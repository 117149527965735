import {
  Button,
  Collapse,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  TextField,
} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {
  createSavingsPlan,
  listSavingsPlans,
  PercentageSavingsPlanData,
  SavingsPlanItem,
  SavingsPlanType,
  SMASavingsPlanData,
} from '../../../clients/SavingsPlanClient'
import {Coin} from '../../../clients/WalletClient'
import {addToastMessage} from '../../../stores/MessageStore'
import {MessageSeverity} from '../../utils/MessageSnackbar'
import {Add, Create, ExpandLess, ExpandMore} from '@mui/icons-material'
import {SavingsPlanCard} from './SavingsPlanCard'
import {useGetAccessToken} from '../../utils/getAccessToken'

export const SavingsPlanSettings = () => {
  const getAccessToken = useGetAccessToken()
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [savingsPlans, setSavingsPlans] = useState<SavingsPlanItem[]>()

  const loadData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await listSavingsPlans(await getAccessToken())
      if (response) {
        setSavingsPlans(response.savingsPlans)
      }
    } finally {
      setLoading(false)
    }
  }, [getAccessToken, setSavingsPlans, setLoading])

  useEffect(() => {
    loadData()
  }, [loadData])


  return (
    <>
      {loading && (
        <>
          <Skeleton sx={{mb: -3}} height={100} width="100%"/>
          <Skeleton sx={{mb: -3}} height={100} width="100%"/>
        </>
      )}
      {
        !loading &&
        savingsPlans &&
        savingsPlans.map(sp => <SavingsPlanCard key={sp.id} savingsPlan={sp} onReload={loadData}/>)
      }
      {!loading && (
        <List>
          <ListItemButton onClick={() => setOpen(!open)}>
            <ListItemIcon>
              <Add/>
            </ListItemIcon>
            <ListItemText primary="Add Savings Plan"/>
            <ListItemIcon>
              <IconButton edge="end">
                {open ? <ExpandLess/> : <ExpandMore/>}
              </IconButton>
            </ListItemIcon>
          </ListItemButton>
          <Collapse in={open} unmountOnExit>
            <SavingsPlanCreation
              onLoading={loading => setLoading(loading)}
              onReload={() => {
                setOpen(false)
                loadData()
              }}/>
          </Collapse>
        </List>
      )}
    </>
  )
}

export type SavingsPlanCreationProps = {
  onReload: () => void
  onLoading: (loading: boolean) => void
}

const SavingsPlanCreation = ({onReload, onLoading}: SavingsPlanCreationProps) => {
  const getAccessToken = useGetAccessToken()
  const [type, setType] = useState<SavingsPlanType>(SavingsPlanType.percentage)
  const [percentage, setPercentage] = useState<number>(10)
  const [smaDays, setSmaDays] = useState<number>(200)
  const [coin, setCoin] = useState<Coin>(Coin.btc)
  const [perBuyMin, setPerBuyMin] = useState<number>(50)
  const [perBuyMax, setPerBuyMax] = useState<number>(200)
  const [perBuyPercentage, setPerBuyPercentage] = useState<number>(100)
  const [description, setDescription] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const onCreate = useCallback(async () => {
    if (
      !coin || !perBuyPercentage ||
      (type === SavingsPlanType.percentage && !percentage) ||
      (type === SavingsPlanType.sma && !smaDays)
    ) {
      addToastMessage('Invalid data', MessageSeverity.error)
      return
    }
    setLoading(true)
    onLoading(true)
    try {
      await createSavingsPlan(await getAccessToken(), {
        type,
        coin: coin,
        data: {
          percentage,
          smaDays,
        } as PercentageSavingsPlanData | SMASavingsPlanData,
        perBuyMax,
        perBuyMin,
        perBuyPercentage,
        description,
      })
      addToastMessage('Successfully created', MessageSeverity.success)
      onReload()
    } finally {
      setLoading(false)
      onLoading(false)
    }
  }, [
    setLoading,
    description,
    onReload,
    getAccessToken,
    onLoading,
    perBuyPercentage,
    perBuyMax,
    perBuyMin,
    percentage,
    coin,
    type,
    smaDays,
  ])

  return (
    <Paper elevation={2} sx={{p: 2, m: 1}}>
      <FormControl fullWidth sx={{mt: 2, mb: 2}} disabled={loading}>
        <InputLabel>Savings Plan Type</InputLabel>
        <Select
          value={type}
          label="Savings Plan Type"
          variant="standard"
          onChange={element => setType(element.target.value as SavingsPlanType)}
        >
          {Object.keys(SavingsPlanType).map(t => (
            <MenuItem key={t} value={t}>{t}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{mt: 2, mb: 2}}>
        <InputLabel>Coin</InputLabel>
        <Select
          value={coin}
          label="Coin"
          variant="standard"
          onChange={element => setCoin(element.target.value as Coin)}
          disabled={loading}
        >
          <MenuItem value={Coin.btc}>{Coin.btc}</MenuItem>
          <MenuItem value={Coin.eth}>{Coin.eth}</MenuItem>
          <MenuItem value={Coin.sol}>{Coin.sol}</MenuItem>
          <MenuItem value={Coin.link}>{Coin.link}</MenuItem>
          <MenuItem value={Coin.atlas}>{Coin.atlas}</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Description"
        variant="standard"
        onChange={e => setDescription(e.target.value)}
        disabled={loading}
        sx={{mt: 2}}
        fullWidth/>
      {type === SavingsPlanType.sma && (
        <FormControl fullWidth sx={{mt: 2, mb: 2}} disabled={loading}>
          <InputLabel>Simple moving average days</InputLabel>
          <Input
            type="number"
            value={smaDays}
            onChange={e => setSmaDays(Number(e.target.value))}
            fullWidth/>
        </FormControl>
      )}
      {type === SavingsPlanType.percentage && (
        <FormControl fullWidth sx={{mt: 2, mb: 2}} disabled={loading}>
          <InputLabel>Percentage</InputLabel>
          <Input
            type="number"
            value={percentage}
            onChange={e => setPercentage(Number(e.target.value))}
            fullWidth/>
        </FormControl>
      )}
      <FormControl fullWidth sx={{mt: 2, mb: 2}} disabled={loading}>
        <InputLabel>Min per buy</InputLabel>
        <Input
          type="number"
          value={perBuyMin}
          onChange={e => setPerBuyMin(Number(e.target.value))}
          fullWidth/>
      </FormControl>
      <FormControl fullWidth sx={{mt: 2, mb: 2}} disabled={loading}>
        <InputLabel>Max per buy</InputLabel>
        <Input
          type="number"
          value={perBuyMax}
          onChange={e => setPerBuyMax(Number(e.target.value))}
          fullWidth/>
      </FormControl>
      <FormControl fullWidth sx={{mt: 2, mb: 2}} disabled={loading}>
        <InputLabel>Percentage per buy</InputLabel>
        <Input
          type="number"
          value={perBuyPercentage}
          onChange={e => setPerBuyPercentage(Number(e.target.value))}
          fullWidth/>
      </FormControl>
      {loading && <Skeleton variant="rounded" sx={{mt: 2}} width={120} height={40}/>}
      {!loading && (
        <Button variant="contained" startIcon={<Create/>} onClick={onCreate} sx={{mt: 2}}
                disabled={
                  (type === SavingsPlanType.percentage && !percentage) ||
                  (type === SavingsPlanType.sma && !smaDays) ||
                  !coin ||
                  !setPerBuyPercentage
                }>
          Save
        </Button>
      )}
    </Paper>
  )
}