import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from '@mui/material'
import React, {ReactNode, useState} from 'react'
import {ArrowForward, CopyAll, Edit} from '@mui/icons-material'
import {useCopyToClipboard} from 'usehooks-ts'
import {addToastMessage} from '../../stores/MessageStore'
import {MessageSeverity} from './MessageSnackbar'
import {useStringShortener} from './useStringShortener'

export interface EditableListItemProps {
  name: string
  icon?: ReactNode
  defaultValue?: string
  secretValue?: boolean
  copyable?: boolean
  onChange: (newValue: string) => void
}

export const EditableListItem = (
  {
    name,
    icon,
    secretValue = false,
    copyable = false,
    defaultValue = '-',
    onChange,
  }: EditableListItemProps) => {
  const [, copy] = useCopyToClipboard()
  const [value, setValue] = useState<string>(defaultValue)
  const [newValue, setNewValue] = useState<string>(defaultValue)
  const [open, setOpen] = useState<boolean>(false)
  const {shorten} = useStringShortener()

  const itemText = secretValue ? '<hidden>' : shorten(value)

  return (
    <>
      <ListItem key={name} sx={{pl: 2}}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText secondary={shorten(itemText)} primary={shorten(name)}/>
        <ListItemSecondaryAction>
          {copyable && (
            <IconButton onClick={() => {
              copy(value)
              addToastMessage('Successfully copied', MessageSeverity.success)
            }}>
              <CopyAll/>
            </IconButton>
          )}
          <IconButton edge="end" onClick={() => setOpen(true)}>
            <Edit/>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Dialog key={`${name}-dialog`} open={open} onClose={() => setOpen(false)} PaperProps={{
        sx: {width: '95%'},
      }}>
        <DialogTitle>Change {name}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={name}
            value={newValue}
            fullWidth
            multiline
            variant="standard"
            onChange={e => setNewValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={() => {
            setValue(newValue)
            setOpen(false)
            onChange(newValue)
          }}>Set</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export interface EditableListOptionItemProps {
  name: string
  icon?: ReactNode
  defaultValue: string
  options: string[]
  onChange: (newValue: string) => void
}

export const EditableListOptionItem = ({name, defaultValue, options, onChange, icon}: EditableListOptionItemProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [value, setValue] = useState<string>(defaultValue)
  return (
    <>
      <ListItem key={name} sx={{pl: 2}}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={name} secondary={value}/>
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => setOpen(true)}>
            <Edit/>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Dialog key={`${name}-dialog`} open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Set {name}</DialogTitle>
        <DialogContent>
          <List>
            {options.map(option => (
              <ListItemButton key={option} onClick={() => {
                setValue(option)
                setOpen(false)
                onChange(option)
              }}>
                <ListItemText primary={option}/>
                <IconButton>
                  <ArrowForward/>
                </IconButton>
              </ListItemButton>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  )
}