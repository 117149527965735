import {buildAccessTokenHeader} from './clientUtils'
import {Coin} from './WalletClient'

const apiDomain = process.env.REACT_APP_API_DOMAIN

export const listSavingsPlans = async (accessToken: string) => {
  const response = await fetch(`${apiDomain}/savingsPlans`, {
    headers: buildAccessTokenHeader(accessToken),
  })
  if (response.ok) {
    return (await response.json()) as { savingsPlans: SavingsPlanItem[] }
  }
}

export const createSavingsPlan = async (accessToken: string, request: SavingsPlanRequest) => {
  await fetch(`${apiDomain}/savingsPlans`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'POST',
    body: JSON.stringify(request),
  })
}

export const deleteSavingsPlan = async (accessToken: string, id: string) => {
  await fetch(`${apiDomain}/savingsPlans/${id}`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'DELETE',
  })
}

export enum SavingsPlanType {
  percentage = 'percentage',
  sma = 'sma',
}

export type SavingsPlanRequest = {
  coin: Coin
  type: SavingsPlanType
  data: PercentageSavingsPlanData | SMASavingsPlanData
  description: string | undefined
  perBuyPercentage: number
  perBuyMin: number | undefined
  perBuyMax: number | undefined
}

export type PercentageSavingsPlanData = {
  percentage: number
}

export type SMASavingsPlanData = {
  smaDays: number
}

export type SavingsPlanItem = {
  id: string
  sub: string
  type: SavingsPlanType
  coin: Coin
  description: string | undefined
  data: PercentageSavingsPlanData | SMASavingsPlanData
  perBuyPercentage: number
  perBuyMin: number | undefined
  perBuyMax: number | undefined
}