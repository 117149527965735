import React, {useCallback, useEffect, useState} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {Check, Edit, Logout, Save, Visibility, VisibilityOff} from '@mui/icons-material'
import {Currency, Profile} from '../types'
import {
  fetchSharedProfiles,
  getCurrentProfile,
  updateOwnAutoHide,
  updateOwnCurrency,
  updateOwnDisplayName,
} from '../../clients/ProfileClient'
import {useMedia} from '../utils/useMedia'
import {ConfirmationButton} from '../utils/ConfirmationButton'
import {getRequiredEnvVariable} from '../utils/getRequiredEnvVariable'
import {useGetAccessToken} from '../utils/getAccessToken'

export const ProfileSettings = () => {
  const appDomain = getRequiredEnvVariable('REACT_APP_DOMAIN')
  const navigate = useNavigate()
  const media = useMedia()
  const getAccessToken = useGetAccessToken()
  const {user, logout} = useAuth0()
  const [profile, setProfile] = useState<Profile>()
  const [sharedProfiles, setSharedProfiles] = useState<SharedProfileInfo[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [displayName, setDisplayName] = useState<string>(profile?.displayName || '-')

  const loadProfile = useCallback(async () => {
    setLoading(true)
    try {
      const accessToken = await getAccessToken()
      const response = await getCurrentProfile(accessToken)
      setProfile(response)
      setDisplayName(response?.displayName || '-')
      const sharedProfilesResponse = await fetchSharedProfiles(accessToken)
      setSharedProfiles(sharedProfilesResponse)
    } finally {
      setLoading(false)
    }
  }, [setProfile, getAccessToken, setSharedProfiles])

  const updateDisplayName = useCallback(async (displayName: string) => {
    setLoading(true)
    try {
      await updateOwnDisplayName(await getAccessToken(), displayName)
      await loadProfile()
    } finally {
      setLoading(false)
    }
  }, [loadProfile, getAccessToken, setLoading])

  const updateAutoHide = useCallback(async (autoHide: boolean) => {
    setLoading(true)
    try {
      await updateOwnAutoHide(await getAccessToken(), autoHide)
      await loadProfile()
    } finally {
      setLoading(false)
    }
  }, [loadProfile, getAccessToken, setLoading])

  const updateCurrency = useCallback(async (currency: string) => {
    setLoading(true)
    try {
      await updateOwnCurrency(await getAccessToken(), currency)
      await loadProfile()
    } finally {
      setLoading(false)
    }
  }, [loadProfile, getAccessToken, setLoading])

  useEffect(() => {
    loadProfile()
  }, [loadProfile, user, navigate])

  const showSkeleton = loading || !user || !profile

  return (
    <>
      <Grid container>
        <Grid item xs={12} alignItems="center" justifyContent="center">
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
            {(loading || !user) ? <Skeleton variant="circular" width={128} height={128}/> :
              <Avatar
                variant="circular"
                src={user.picture}
                alt="avatar"
                sx={{width: 128, height: 128}}
              />}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={2} sx={{padding: 3, marginTop: 2}}>
            <Stack spacing={2}>
              {
                showSkeleton ? <>
                    <Skeleton variant="text" height={60}/>
                    <Skeleton variant="text" height={60}/>
                    <Skeleton variant="text" height={60}/>
                  </> :
                  (
                    <>
                      <TextField variant="standard" contentEditable={false} fullWidth label="Name" value={user.name}/>
                      <TextField variant="standard" fullWidth label="Username" value={displayName}
                                 onChange={e => setDisplayName(e.target.value)}
                                 InputProps={{
                                   endAdornment: profile?.displayName !== displayName ? (
                                     <IconButton onClick={() => updateDisplayName(displayName)}>
                                       <Save/>
                                     </IconButton>
                                   ) : (
                                     <IconButton disabled>
                                       <Edit/>
                                     </IconButton>
                                   ),
                                 }}
                      />
                      <TextField variant="standard" contentEditable={false} fullWidth label="Email" value={user.email}/>
                      {sharedProfiles?.length && (
                        <>
                          <Box style={{marginTop: 25}}>
                            <Divider/>
                            <Typography sx={{mt: 2}} variant="h5">Profile shared with:</Typography>
                            {sharedProfiles.map(s => (
                              <Typography variant="caption" sx={{ml: 2, mt: 0.5}}>
                                <b>{s.displayName}</b> ({s.sub})
                              </Typography>
                            ))}
                          </Box>
                          <Divider/>
                        </>
                      )}
                      <Grid container width="100%" spacing={2}>
                        <Grid item xs={12} lg={6} ml={-2}>
                          <ButtonGroup color="primary">
                            <Button variant="contained" color="secondary">Currency</Button>
                            {Object.values(Currency).map(c => (
                              <Button
                                startIcon={c === profile?.currency && !media.isSuperSmall && <Check/>}
                                key={c}
                                variant={profile?.currency === c ? 'contained' : 'outlined'}
                                onClick={() => updateCurrency(c)}>
                                {media.isSuperSmall && c}
                                {!media.isSuperSmall && (c === Currency.USD ? `${c} ($)` : `${c} (€)`)}
                              </Button>
                            ))}
                          </ButtonGroup>
                        </Grid>
                        <Grid item xs={12} lg={6} ml={-2}>
                          <ButtonGroup color="primary">
                            <Button variant="contained" color="secondary">Roles</Button>
                            {profile?.roles?.map(r => (
                              <Button
                                startIcon={!media.isSuperSmall && <Check/>}
                                variant="contained"
                                key={r}
                              >
                                {r}
                              </Button>
                            ))}
                          </ButtonGroup>
                        </Grid>
                        <Grid item xs={12} lg={6} ml={-2}>
                          <ButtonGroup color="primary">
                            <Button variant="contained" color="secondary">Auto Hide</Button>
                            <Button
                              startIcon={<VisibilityOff/>}
                              onClick={() => updateAutoHide(true)}
                              key="on"
                              variant={profile?.autoHide ? 'contained' : 'outlined'}
                            >
                              ON
                            </Button>
                            <Button
                              startIcon={<Visibility/>}
                              onClick={() => updateAutoHide(false)}
                              variant={!profile?.autoHide ? 'contained' : 'outlined'}
                              key="off"
                            >
                              OFF
                            </Button>
                          </ButtonGroup>
                        </Grid>
                      </Grid>
                    </>
                  )
              }
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sx={{marginTop: 2}} display="flex" justifyContent="right">
          <ConfirmationButton
            button={{
              startIcon: <Logout/>,
              variant: 'contained',
              sx: (t) => ({
                background: t.palette.error.light,
              }),
            }}
            action={() => logout({
              logoutParams: {
                returnTo: appDomain,
              },
            })}
            actionName="log out"
          >
            Logout
          </ConfirmationButton>
        </Grid>
      </Grid>
    </>
  )
}

interface SharedProfileInfo {
  sub: string
  displayName: string
}