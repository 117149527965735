import {
  Button,
  Collapse,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  TextField,
} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {Coin} from '../../../clients/WalletClient'
import {addToastMessage} from '../../../stores/MessageStore'
import {MessageSeverity} from '../../utils/MessageSnackbar'
import {Add, Create, ExpandLess, ExpandMore} from '@mui/icons-material'
import {createTradingPlan, listTradingPlans, TradingPlanItem} from '../../../clients/TradingClient'
import {TradingPlanCard} from './TradingPlanCard'
import {useGetAccessToken} from '../../utils/getAccessToken'

export const TradingPlanSettings = () => {
  const getAccessToken = useGetAccessToken()
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [tradingPlans, setTradingPlans] = useState<TradingPlanItem[]>()

  const loadData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await listTradingPlans(await getAccessToken())
      if (response) {
        setTradingPlans(response.tradingPlans)
      }
    } finally {
      setLoading(false)
    }
  }, [getAccessToken, setTradingPlans, setLoading])

  useEffect(() => {
    loadData()
  }, [loadData])


  return (
    <>
      {loading && (
        <>
          <Skeleton sx={{mb: -3}} height={100} width="100%"/>
          <Skeleton sx={{mb: -3}} height={100} width="100%"/>
        </>
      )}
      {
        !loading &&
        tradingPlans &&
        tradingPlans.map(tp => <TradingPlanCard key={tp.id} savingsPlan={tp} onReload={loadData}/>)
      }
      {!loading && (
        <List sx={{width: '100%'}}>
          <ListItemButton onClick={() => setOpen(!open)}>
            <ListItemIcon>
              <Add/>
            </ListItemIcon>
            <ListItemText primary="Add Trading Plan"/>
            <ListItemIcon>
              <IconButton edge="end">
                {open ? <ExpandLess/> : <ExpandMore/>}
              </IconButton>
            </ListItemIcon>
          </ListItemButton>
          <Collapse in={open} unmountOnExit>
            <TradingPlanCreation
              onLoading={loading => setLoading(loading)}
              onReload={() => {
                setOpen(false)
                loadData()
              }}/>
          </Collapse>
        </List>
      )}
    </>
  )
}

export type TradingPlanCreationProps = {
  onReload: () => void
  onLoading: (loading: boolean) => void
}

const TradingPlanCreation = ({onReload, onLoading}: TradingPlanCreationProps) => {
  const getAccessToken = useGetAccessToken()
  const [smaDays, setSmaDays] = useState<number>(200)
  const [coin, setCoin] = useState<Coin>(Coin.btc)
  const [perBuyMax, setPerBuyMax] = useState<number>(200)
  const [description, setDescription] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const onCreate = useCallback(async () => {
    if (!coin || !smaDays) {
      addToastMessage('Invalid data', MessageSeverity.error)
      return
    }
    setLoading(true)
    onLoading(true)
    try {
      await createTradingPlan(await getAccessToken(), {
        coin: coin,
        smaDays,
        perBuyMax,
        description,
      })
      addToastMessage('Successfully created', MessageSeverity.success)
      onReload()
    } finally {
      setLoading(false)
      onLoading(false)
    }
  }, [
    setLoading,
    description,
    onReload,
    getAccessToken,
    onLoading,
    perBuyMax,
    coin,
    smaDays,
  ])

  return (
    <Paper elevation={2} sx={{p: 2, m: 1}}>
      <FormControl fullWidth sx={{mt: 2, mb: 2}}>
        <InputLabel>Coin</InputLabel>
        <Select
          value={coin}
          label="Coin"
          variant="standard"
          onChange={element => setCoin(element.target.value as Coin)}
          disabled={loading}
        >
          <MenuItem value={Coin.btc}>{Coin.btc}</MenuItem>
          <MenuItem value={Coin.eth}>{Coin.eth}</MenuItem>
          <MenuItem value={Coin.sol}>{Coin.sol}</MenuItem>
          <MenuItem value={Coin.link}>{Coin.link}</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Description"
        variant="standard"
        onChange={e => setDescription(e.target.value)}
        disabled={loading}
        sx={{mt: 2}}
        fullWidth/>
      <FormControl fullWidth sx={{mt: 2, mb: 2}} disabled={loading}>
        <InputLabel>Simple moving average days</InputLabel>
        <Input
          type="number"
          value={smaDays}
          onChange={e => setSmaDays(Number(e.target.value))}
          fullWidth/>
      </FormControl>
      <FormControl fullWidth sx={{mt: 2, mb: 2}} disabled={loading}>
        <InputLabel>Max per buy</InputLabel>
        <Input
          type="number"
          value={perBuyMax}
          onChange={e => setPerBuyMax(Number(e.target.value))}
          fullWidth/>
      </FormControl>
      {loading && <Skeleton variant="rounded" sx={{mt: 2}} width={120} height={40}/>}
      {!loading && (
        <Button
          variant="contained"
          startIcon={<Create/>}
          onClick={onCreate}
          sx={{mt: 2}}
          disabled={!smaDays || !coin}>
          Save
        </Button>
      )}
    </Paper>
  )
}