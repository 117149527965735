import {TradeHistoryItem} from '../../../clients/ExchangeClient'
import React, {useState} from 'react'
import {Coin} from '../../../clients/WalletClient'
import {Card, CardContent, CardHeader, Collapse, IconButton, Typography} from '@mui/material'
import {CoinImage} from '../../utils/CoinImage'
import {formatCoin} from '../../utils/round'
import {ExpandLess, ExpandMore} from '@mui/icons-material'
import {CoinInfoItem} from '../../../clients/RatesClient'

export type KrakenInfoViewProps = {
  trades: TradeHistoryItem[]
  coinInfos: CoinInfoItem[]
}

const calculateCoins = (trades: TradeHistoryItem[], coinInfos: CoinInfoItem[]) => {
  const coins = new Map<Coin, KrakenCoinInfo>()
  trades.forEach(t => {
    const existing = coins.get(t.coin)
    const coinInfo = coinInfos.find(c => c.code === t.coin)
    if (!existing) {
      coins.set(t.coin, {
        amount: Number(t.vol),
        price: Number(t.price),
        coin: t.coin,
        currentPrice: coinInfo!.rate,
      })
      return
    }
    const amount = Number(t.vol) + Number(existing.amount)
    const price =
      (Number(t.vol) * Number(t.price) + existing.price * existing.amount) /
      (Number(t.vol) + existing.amount)
    coins.set(t.coin, {
      coin: t.coin,
      amount,
      price,
      currentPrice: coinInfo!.rate,
    })
  })
  return coins
}

export const KrakenInfoView = ({trades, coinInfos}: KrakenInfoViewProps) => {
  const coins = calculateCoins(trades, coinInfos)
  return (
    <>
      {Array.from(coins.values()).map(c => <KrakenCoinInfoCard key={c.coin} info={c}/>)}
    </>
  )
}

type KrakenCoinInfo = {
  coin: Coin
  amount: number
  price: number
  currentPrice: number
}

const KrakenCoinInfoCard = ({info}: { info: KrakenCoinInfo }) => {
  const [open, setOpen] = useState<boolean>()
  const overallDifference = (info.currentPrice - info.price) * info.amount
  return (
    <Card sx={{m: 1}}>
      <CardHeader avatar={<CoinImage coin={info.coin}/>}
                  onClick={() => setOpen(!open)}
                  title={<Typography fontWeight="bold">{info.coin}</Typography>}
                  subheader={
                    <Typography fontWeight="bold" color={overallDifference > 0 ? 'green' : 'red'}>
                      {formatCoin(overallDifference)} €
                    </Typography>
                  }
                  action={
                    <IconButton>
                      {open ? <ExpandLess fontSize="large"/> : <ExpandMore fontSize="large"/>}
                    </IconButton>
                  }
      />
      <Collapse in={open} unmountOnExit>
        <CardContent>
          <Typography>Amount: {formatCoin(info.amount)} {info.coin}</Typography>
          <Typography>Buy in Price: {formatCoin(info.price)} €</Typography>
          <Typography>Current Price: {formatCoin(info.currentPrice)} €</Typography>
        </CardContent>
      </Collapse>
    </Card>
  )
}