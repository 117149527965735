import {Collapse, Grid, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Skeleton} from '@mui/material'
import {Add, ExpandLess, ExpandMore} from '@mui/icons-material'
import React, {useCallback, useEffect, useState} from 'react'
import {listOwnWallets, WalletResponse} from '../../clients/WalletClient'
import {WalletCreation} from './WalletCreation'
import {WalletCard} from './WalletCard'
import {useGetAccessToken} from '../utils/getAccessToken'

export const WalletSettings = () => {
  const getAccessToken = useGetAccessToken()
  const [open, setOpen] = useState<boolean>()
  const [wallets, setWallets] = useState<WalletResponse>()
  const [loading, setLoading] = useState<boolean>()

  const loadWallets = useCallback(async () => {
    setLoading(true)
    try {
      const wallets = await listOwnWallets(await getAccessToken())
      setWallets(wallets)
    } finally {
      setLoading(false)
    }
  }, [getAccessToken])

  useEffect(() => {
    loadWallets()
  }, [loadWallets])

  return (
    <>
      <Grid container>
        {loading && (
          <>
            <Skeleton sx={{mb: -3}} height={100} width="100%"/>
            <Skeleton sx={{mb: -3}} height={100} width="100%"/>
            <Skeleton sx={{mb: -3}} height={100} width="100%"/>
            <Skeleton sx={{mb: -3}} height={100} width="100%"/>
          </>
        )}
        {!loading && wallets?.wallets?.map(wallet =>
          <WalletCard onReload={loadWallets} key={wallet.id} wallet={wallet}/>
        )}
        {!loading && (
          <List sx={{width: '100%'}}>
            <ListItemButton onClick={() => setOpen(!open)}>
              <ListItemIcon>
                <Add/>
              </ListItemIcon>
              <ListItemText primary="Add Wallet"/>
              <ListItemIcon>
                <IconButton edge="end">
                  {open ? <ExpandLess/> : <ExpandMore/>}
                </IconButton>
              </ListItemIcon>
            </ListItemButton>
            <Collapse in={open} unmountOnExit>
              <WalletCreation onBack={() => {
                setOpen(false)
                loadWallets()
              }}/>
            </Collapse>
          </List>
        )}
      </Grid>
    </>
  )
}
