import React, {ReactNode, useCallback, useState} from 'react'
import {Button, FormControl, Input, InputLabel, MenuItem, Paper, Select, Skeleton, TextField} from '@mui/material'
import {Blockchain, Coin, createChainWallet, createStaticWallet, WalletType} from '../../clients/WalletClient'
import {Create} from '@mui/icons-material'
import {addToastMessage} from '../../stores/MessageStore'
import {MessageSeverity} from '../utils/MessageSnackbar'
import {useGetAccessToken} from '../utils/getAccessToken'

export type WalletCreationProps = {
  onBack: () => void
}

export const WalletCreation = ({onBack}: WalletCreationProps) => {
  const [type, setType] = useState<WalletType>(WalletType.blockchain)
  const [loading, setLoading] = useState<boolean>(false)

  const form: Record<WalletType, ReactNode> = {
    [WalletType.blockchain]: <BlockchainWalletCreation onLoading={loading => setLoading(loading)} onReload={onBack}/>,
    [WalletType.static]: <StaticWalletCreation onLoading={loading => setLoading(loading)} onReload={onBack}/>,
  }
  return (
    <Paper elevation={2} sx={{p: 2, m: 1}}>
      <FormControl fullWidth sx={{mt: 2, mb: 2}} disabled={loading}>
        <InputLabel>Wallet type</InputLabel>
        <Select
          value={type}
          label="Wallet type"
          variant="standard"
          onChange={element => setType(element.target.value as WalletType)}
        >
          <MenuItem value={WalletType.blockchain}>{WalletType.blockchain}</MenuItem>
          <MenuItem value={WalletType.static}>{WalletType.static}</MenuItem>
        </Select>
      </FormControl>
      {form[type]}
    </Paper>
  )
}

export type BlockchainWalletCreationProps = {
  onReload: () => void
  onLoading: (loading: boolean) => void
}

const BlockchainWalletCreation = ({onReload, onLoading}: BlockchainWalletCreationProps) => {
  const getAccessToken = useGetAccessToken()
  const [chain, setChain] = useState<Blockchain>(Blockchain.bitcoin)
  const [address, setAddress] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [coin, setCoin] = useState<Coin>(Coin.btc)
  const [loading, setLoading] = useState<boolean>(false)

  const onCreate = useCallback(async () => {
    setLoading(true)
    onLoading(true)
    try {
      if (!address || !chain || !coin) {
        return
      }
      await createChainWallet(await getAccessToken(), {
        address,
        chain,
        coin,
        description: description || '',
      })
      addToastMessage('Successfully created', MessageSeverity.success)
      onReload()
    } finally {
      setLoading(false)
      onLoading(false)
    }
  }, [setLoading, chain, coin, address, description, onReload, getAccessToken, onLoading])

  return (
    <>
      <FormControl fullWidth sx={{mt: 2, mb: 2}}>
        <InputLabel>Blockchain</InputLabel>
        <Select
          value={chain}
          label="Blockchain"
          variant="standard"
          onChange={element => setChain(element.target.value as Blockchain)}
          disabled={loading}
        >
          <MenuItem value={Blockchain.bitcoin}>{Blockchain.bitcoin}</MenuItem>
          <MenuItem value={Blockchain.ethereum}>{Blockchain.ethereum}</MenuItem>
          <MenuItem value={Blockchain.solana}>{Blockchain.solana}</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{mt: 2, mb: 2}} disabled={loading}>
        <InputLabel>Coin</InputLabel>
        <Select
          value={coin}
          label="Coin"
          variant="standard"
          onChange={element => setCoin(element.target.value as Coin)}
        >
          {Object.values(Coin).filter(it => it !== Coin.eur).map(it => (
            <MenuItem value={it}>{it}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Address"
        variant="standard"
        onChange={e => setAddress(e.target.value)}
        disabled={loading}
        fullWidth/>
      <TextField
        label="Description"
        variant="standard"
        onChange={e => setDescription(e.target.value)}
        disabled={loading}
        sx={{mt: 2}}
        fullWidth/>
      {loading && <Skeleton variant="rounded" sx={{mt: 2}} width={120} height={40}/>}
      {!loading && (
        <Button variant="contained" startIcon={<Create/>} onClick={onCreate} sx={{mt: 2}} disabled={!address || !chain}>
          Create
        </Button>
      )}
    </>
  )
}

export type StaticWalletCreationProps = {
  onReload: () => void
  onLoading: (loading: boolean) => void
}

const StaticWalletCreation = ({onReload, onLoading}: StaticWalletCreationProps) => {
  const getAccessToken = useGetAccessToken()
  const [coin, setCoin] = useState<Coin>(Coin.btc)
  const [amount, setAmount] = useState<number>()
  const [description, setDescription] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  const onCreate = useCallback(async () => {
    setLoading(true)
    onLoading(true)
    try {
      if (!coin || !amount) {
        return
      }
      await createStaticWallet(await getAccessToken(), {
        amount,
        coin,
        description: description || '',
      })
      onReload()
      addToastMessage('Successfully created', MessageSeverity.success)
    } finally {
      setLoading(false)
      onLoading(false)
    }
  }, [getAccessToken, coin, amount, description, setLoading, onReload, onLoading])

  return (
    <>
      <FormControl fullWidth sx={{mt: 2, mb: 2}} disabled={loading}>
        <InputLabel>Blockchain</InputLabel>
        <Select
          value={coin}
          label="Coin"
          variant="standard"
          onChange={element => setCoin(element.target.value as Coin)}
        >
          {Object.values(Coin).filter(it => it !== Coin.eur).map(it => (
            <MenuItem value={it}>{it}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{mt: 2, mb: 2}} disabled={loading}>
        <InputLabel>Amount</InputLabel>
        <Input
          type="number"
          onChange={e => setAmount(Number(e.target.value))}
          fullWidth/>
      </FormControl>
      <TextField
        label="Description"
        variant="standard"
        disabled={loading}
        sx={{mt: 2}}
        onChange={e => setDescription(e.target.value)}
        fullWidth/>
      {loading && <Skeleton variant="rounded" width={120} height={40}/>}
      {!loading && (
        <Button variant="contained" startIcon={<Create/>} onClick={onCreate} sx={{mt: 2}} disabled={!coin || !amount}>
          Create
        </Button>
      )}
    </>
  )
}