import {PageLayout} from '../PageLayout'
import React from 'react'
import {WalletSettings} from './WalletSettings'
import {ExchangeSettings} from './ExchangeSettings'
import {SavingsPlanSettings} from './savingsPlans/SavingsPlanSettings'
import {TradingPlanSettings} from './tradingPlans/TradingPlanSettings'
import {Business, CurrencyExchange, EventRepeat, Wallet} from '@mui/icons-material'
import {TabsContainer} from '../utils/TabsContainer'

export const SettingsPage = () => {
  return (
    <PageLayout>
      <TabsContainer tabs={[
        {
          label: 'wallets',
          key: 'wallets',
          icon: <Wallet/>,
          render: () => <WalletSettings/>,
        },
        {
          label: 'exchanges',
          key: 'exchanges',
          icon: <Business/>,
          render: () => <ExchangeSettings/>,
        },
        {
          label: 'savings',
          key: 'savingsPlans',
          icon: <EventRepeat/>,
          render: () => <SavingsPlanSettings/>,
        },
        {
          label: 'trading',
          key: 'tradingPlans',
          icon: <CurrencyExchange/>,
          render: () => <TradingPlanSettings/>,
        },
      ]}/>
    </PageLayout>
  )
}