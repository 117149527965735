import {Profile, ProfileList, SharedProfile} from '../components/types'
import {buildAccessTokenHeader} from './clientUtils'

const apiDomain = process.env.REACT_APP_API_DOMAIN

export const getCurrentProfile = async (accessToken: string) => {
  const response = await fetch(`${apiDomain}/profile`, {
    headers: buildAccessTokenHeader(accessToken),
  })
  if (response.ok) {
    return (await response.json()) as Profile
  }
}

export const loadProfile = async (accessToken: string, sub: string) => {
  const response = await fetch(`${apiDomain}/admin/profile/${encodeURIComponent(sub)}`, {
    headers: buildAccessTokenHeader(accessToken),
  })
  if (response.ok) {
    return (await response.json()) as Profile
  } else {
    throw new Error('profile not found')
  }
}

export const listProfiles = async (accessToken: string) => {
  const response = await fetch(`${apiDomain}/admin/profiles`, {
    headers: buildAccessTokenHeader(accessToken),
  })
  if (response.ok) {
    return (await response.json()) as ProfileList
  }
}

export const addRoleToUser = async (accessToken: string, role: string, sub: string) => {
  await fetch(`${apiDomain}/admin/profile/roles`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'PUT',
    body: JSON.stringify({
      role,
      sub,
    }),
  })
}

export const removeRoleFromUser = async (accessToken: string, role: string, sub: string) => {
  await fetch(`${apiDomain}/admin/profile/roles`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'DELETE',
    body: JSON.stringify({
      role,
      sub,
    }),
  })
}

export const updateOwnDisplayName = async (accessToken: string, displayName: string) => {
  await fetch(`${apiDomain}/profile/displayName`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'PUT',
    body: JSON.stringify({
      displayName,
    }),
  })
}

export const updateOwnCurrency = async (accessToken: string, currency: string) => {
  await fetch(`${apiDomain}/profile/currency`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'PUT',
    body: JSON.stringify({
      currency,
    }),
  })
}

export const updateOwnAutoHide = async (accessToken: string, autoHide: boolean) => {
  await fetch(`${apiDomain}/profile/autoHide`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'PUT',
    body: JSON.stringify({
      autoHide,
    }),
  })
}

export const createShareId = async (accessToken: string) => {
  const response = await fetch(`${apiDomain}/profile/share`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'POST',
  })
  const body = await response.json()
  return body.id as string
}

export const addShareId = async (accessToken: string, id: string) => {
  await fetch(`${apiDomain}/profile/share/${id}`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'POST',
  })
}

export const fetchSharedProfiles = async (accessToken: string) => {
  const response = await fetch(`${apiDomain}/profile/shared`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'GET',
  })
  const body = await response.json()
  return body as SharedProfile[]
}