import {buildAccessTokenHeader} from './clientUtils'

const apiDomain = process.env.REACT_APP_API_DOMAIN

export const createChainWallet = async (accessToken: string, request: ChainWalletRequest) => {
  await fetch(`${apiDomain}/wallets/blockchain`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'POST',
    body: JSON.stringify(request),
  })
}

export const createStaticWallet = async (accessToken: string, request: StaticWalletRequest) => {
  await fetch(`${apiDomain}/wallets/static`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'POST',
    body: JSON.stringify(request),
  })
}

export const deleteWallet = async (accessToken: string, id: string) => {
  await fetch(`${apiDomain}/wallets/${id}`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'DELETE',
  })
}

export const listWalletsForProfile = async (accessToken: string, sub: string) => {
  const response = await fetch(`${apiDomain}/admin/wallets/profile/${sub}`, {
    headers: buildAccessTokenHeader(accessToken),
  })
  return await response.json() as WalletResponse
}

export const listOwnWallets = async (accessToken: string) => {
  const response = await fetch(`${apiDomain}/wallets`, {
    headers: buildAccessTokenHeader(accessToken),
  })
  return await response.json() as WalletResponse
}

export const listIntradayCandlesForCoin = async (accessToken: string, coin: Coin) => {
  const response = await fetch(`${apiDomain}/wallets/intraday/coin/${coin}`, {
    headers: buildAccessTokenHeader(accessToken),
  })
  return await response.json() as { valuations: ValuationCandles[] }
}

export const listIntradayCandlesForProfile = async (accessToken: string) => {
  const response = await fetch(`${apiDomain}/wallets/intraday`, {
    headers: buildAccessTokenHeader(accessToken),
  })
  return await response.json() as { valuations: ValuationCandles[] }
}

export const updateWalletAmount = async (accessToken: string, id: string, newAmount: number) => {
  await fetch(`${apiDomain}/wallets/${id}/amount`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'PUT',
    body: JSON.stringify({
      amount: newAmount,
    }),
  })
}

export const updateWalletDescription = async (accessToken: string, id: string, newDescription: string | undefined) => {
  await fetch(`${apiDomain}/wallets/${id}/description`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'PUT',
    body: JSON.stringify({
      description: newDescription,
    }),
  })
}

export type Valuation = {
  amount: number
  eurRate: number
  usdRate: number
  usdValue: number
  eurValue: number
}

export type ValuationCandles = {
  time: number
  bucket: string
  origin: string
  coin: Coin,
  sub: string
  open: Valuation
  high: Valuation
  low: Valuation
  close: Valuation
}

export type StaticWalletRequest = {
  coin: Coin
  amount: number
  description: string
}

export type ChainWalletRequest = {
  address: string
  chain: Blockchain
  coin: Coin
  description: string
}

export enum Blockchain {
  solana = 'solana',
  bitcoin = 'bitcoin',
  ethereum = 'ethereum',
}

export enum Coin {
  // coins
  sol = 'SOL',
  btc = 'BTC',
  eth = 'ETH',
  cro = 'CRO',
  link = 'LINK',
  atlas = 'ATLAS',
  pyth = 'PYTH',
  sui = 'SUI',
  orca = 'ORCA',
  ray = 'RAY',
  sei = 'SEI',
  nos = 'NOS',
  taki = 'TAKI',
  shdw = 'SHDW',
  lfnty = 'LFNTY',
  jup = 'JUP',
  whales = 'WHALES',
  slnd = 'SLND',
  // cash
  eur = 'EUR',
  usdc = 'USDC',
  gene = 'GENE',
  chat = 'CHAT',
}

export type WalletResponse = {
  wallets: WalletItem[]
}

export type ChainWalletData = {
  address: string
  coin: Coin
  chain: Blockchain
}

export type StaticWalletData = {
  coin: Coin
  amount: number
}

export type WalletItem = {
  id: string
  type: WalletType
  description: string
  sub: string
  created: string
  updated: string
  data: ChainWalletData | StaticWalletData
}

export enum WalletType {
  blockchain = 'blockchain',
  static = 'static',
}
