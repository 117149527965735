import React, {useCallback, useEffect, useState} from 'react'
import {Coin, Valuation} from '../../clients/WalletClient'
import {getCurrentProfile} from '../../clients/ProfileClient'
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Fab,
  Grid,
  IconButton,
  Paper,
  Popover,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import {CoinImage} from '../utils/CoinImage'
import {ChangeIndicator} from './ChangeIndicator'
import {formatCoin, formatEur, formatPercentage} from '../utils/round'
import {CoinInfoItem, CoinInfoItemDelta, loadCoinInfos} from '../../clients/RatesClient'
import {DateRange, Refresh, Visibility} from '@mui/icons-material'
import {EmptyDashboardInfo} from './EmptyDashboardInfo'
import {useMedia} from '../utils/useMedia'
import {TimeFrame} from '../utils/TimeFrame'
import {NumbersItemText} from './components/NumbersItemText'
import {range} from '../utils/range'
import {Ath, Aths} from '../types'
import {useGetAccessToken} from '../utils/getAccessToken'
import {addToastMessage} from '../../stores/MessageStore'
import {MessageSeverity} from '../utils/MessageSnackbar'

const cashCoins: string[] = [Coin.eur, Coin.usdc]

export const DashboardNumbers = () => {
  const getAccessToken = useGetAccessToken()
  const [loading, setLoading] = useState<boolean>(true)
  const [timeFrame, setTimeFrame] = useState<keyof CoinInfoItemDelta>('day')
  const [valuations, setValuations] = useState<{ [coin: string]: Valuation }>()
  const [isEmpty, setEmpty] = useState<boolean>(false)
  const [aths, setAths] = useState<Aths>()
  const [athOpen, setAthOpen] = useState<boolean>(false)
  const [athAnchorEl, setAthAnchorEl] = React.useState<HTMLElement | null>(null)
  const [hide, setHide] = useState<boolean>(true)
  const [coinInfos, setCoinInfos] = useState<CoinInfoItem[]>([])

  const loadData = useCallback(async () => {
    setLoading(true)
    try {
      const accessToken = await getAccessToken()
      const profile = await getCurrentProfile(accessToken)
      if (profile && profile.amounts) {
        setValuations(profile.valuations)
        setEmpty(false)
        setAths(profile.aths)
      } else if (profile && (!profile.amounts || Object.keys(profile.amounts).length === 0)) {
        setEmpty(true)
      }
    } finally {
      setLoading(false)
    }
  }, [setLoading, getAccessToken, setValuations, setEmpty, setHide])

  const loadAllCoinInfos = useCallback(async () => {
    try {
      setCoinInfos([])
      const accessToken = await getAccessToken()
      const response = await loadCoinInfos(accessToken, Object.values(Coin) as Coin[])
      setCoinInfos(response)
    } catch (e) {
      setCoinInfos([])
      addToastMessage('failed to load coin infos', MessageSeverity.error)
    }
  }, [setCoinInfos])

  const loadAll = useCallback(() => {
    loadAllCoinInfos()
    loadData()
  }, [loadAllCoinInfos, loadData])

  useEffect(() => {
    loadAll()
  }, [loadAll])

  if (isEmpty) {
    return <EmptyDashboardInfo/>
  }

  const overallEur = Object.entries(valuations || {})
    .map(([, v]) => v.eurValue)
    .reduce((v1, v2) => v1 + v2, 0)

  const cash = (valuations && Object.keys(valuations).length > 0) ?
    Object.keys(valuations)
      .filter(it => cashCoins.includes(it))
      .map(it => {
        return valuations[it].eurValue
      })
      .reduce((v1, v2) => v1 + v2, 0) : 0

  const handleOpenAth = (event: React.MouseEvent<HTMLElement>) => {
    setAthAnchorEl(event.currentTarget)
    setAthOpen(!athOpen)
  }

  return (
    <>
      <Stack direction="row" alignItems="end" justifyContent="stretch" sx={{ml: 0.5, mr: 1}}>
        <Typography fontWeight="bold" variant="h6">Assets</Typography>
        <Typography fontStyle="italic" fontSize="smaller" marginLeft={1} flex={1} marginBottom={0.5} textAlign="start">
          {
            !loading && !hide && valuations && formatEur(Object.keys(valuations)
              .filter(it => !cashCoins.includes(it))
              .map(it => valuations[it].eurValue)
              .reduce((v1, v2) => v1 + v2, 0))
          }
          {!loading && hide && '*** €'}
          {loading && <Skeleton width={85}/>}
        </Typography>
        <TimeFrameSelection onChange={setTimeFrame}/>
      </Stack>
      <Fab variant="extended"
           color="primary"
           aria-label="refresh"
           onClick={loadAll}
           disabled={loading}
           sx={theme => ({
             position: 'fixed',
             left: 80,
             bottom: 72,
             background: theme.palette.primary.main,
           })}>
        {!loading && <Refresh/>}
        {loading && <CircularProgress size={20}/>}
      </Fab>
      <Fab
        variant="extended"
        color="primary"
        aria-label="show"
        onMouseDown={() => setHide(false)}
        onTouchStart={() => setHide(false)}
        onMouseUp={() => setHide(true)}
        onTouchEnd={() => setHide(true)}
        onTouchMove={() => setHide(true)}
        onDoubleClick={() => {
          setHide(false)
        }}
        sx={theme => ({
          position: 'fixed',
          left: 145,
          bottom: 72,
          background: theme.palette.primary.main,
        })}>
        <Visibility/>
      </Fab>
      <Grid
        container
        style={{marginTop: 5}}
        spacing={0.75}
        width="100%"
      >
        {(
          loading && !valuations && range(5).map((_, i) => (
            <Grid key={i} item xs={12} mt={-1.75} mb={-1.75}>
              <Skeleton width="100%" height={85} animation="wave"/>
            </Grid>
          ))
        )}
        {valuations && Object.keys(valuations)
          .filter(it => !cashCoins.includes(it))
          .sort((a1, a2) => valuations[a2].eurValue - valuations[a1].eurValue)
          .map(c => (
            <Grid key={c} item xs={12}>
              <DashboardNumberItem
                coin={c as Coin}
                amount={valuations[c].amount}
                rate={valuations[c].eurRate}
                timeFrame={timeFrame}
                loading={loading}
                hide={hide}
                overallEur={overallEur}
                coinInfo={coinInfos?.find(it => it.code === c)}
                ath={aths?.coins?.[c as Coin]}/>
            </Grid>
          ))}
      </Grid>
      {(loading || cash > 0.10) && (<>
          <Stack direction="row" alignItems="end" justifyContent="stretch" sx={{ml: 0.5, mt: 1.5}}>
            <Typography fontWeight="bold" variant="h6">Cash</Typography>
            <Typography
              fontStyle="italic"
              fontSize="smaller"
              marginLeft={1}
              flex={1}
              marginBottom={0.5}
              textAlign="start">
              {
                !loading && !hide && valuations && Object.keys(valuations).length > 0 && formatEur(Object.keys(valuations)
                  .filter(it => cashCoins.includes(it))
                  .map(it => {
                    const rate = valuations[it].eurRate
                    return rate ? valuations[it].amount * rate : valuations[it].eurValue
                  })
                  .reduce((v1, v2) => v1 + v2, 0))
              }
              {!loading && hide && '*** €'}
              {loading && <Skeleton width={85}/>}
            </Typography>
          </Stack>
          <Grid
            container
            width="100%"
            mt={0.25}
            spacing={0.75}
          >
            {(loading || !valuations) && (
              <Grid item xs={12} mt={-1.75} mb={-1.75}>
                <Skeleton width="100%" height={85} animation="wave"/>
              </Grid>
            )}
            {!loading && valuations && Object.keys(valuations)
              .filter(it => cashCoins.includes(it))
              .sort((a1, a2) => valuations[a2].eurValue - valuations[a1].eurValue)
              .map(c => (
                <Grid key={c} item xs={12}>
                  <DashboardNumberItem
                    coin={c as Coin}
                    amount={valuations[c].amount}
                    rate={c === Coin.eur ? 1 : valuations[c].eurRate}
                    timeFrame={timeFrame}
                    loading={loading}
                    overallEur={overallEur}
                    hide={hide}
                    ath={aths?.coins?.[c as Coin]}/>
                </Grid>
              ))
            }
          </Grid>
        </>
      )}
      <Stack direction="row" alignItems="end" justifyContent="stretch" sx={{ml: 0.5, mt: 1.25}}>
        <Typography fontWeight="bold" variant="h6">Overall</Typography>
        <Box flex={1}/>
        <Typography
          fontWeight="bold"
          fontStyle="italic"
          variant="h6"
          sx={{mr: 1}}>
          {loading && <Skeleton width={125}/>}
          {!loading && !hide && valuations && formatEur(overallEur)}
          {!loading && hide && '*** €'}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="end" justifyContent="stretch" sx={{ml: 0.5, mt: 0, mb: 2}}>
        <Typography
          onClick={(e) => handleOpenAth(e)}
          fontWeight="bold"
          variant="caption"
        >
          ATH
        </Typography>
        <Popover
          id="ATH"
          open={athOpen}
          anchorEl={athAnchorEl}
          onClose={handleOpenAth}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography sx={{p: 1.5}} variant="h6">
            {aths?.overall?.time?.substring(0, 16)?.replace('T', ' ') || ''}
          </Typography>
        </Popover>
        <Box flex={1}/>
        <Typography
          fontWeight="bold"
          fontStyle="italic"
          variant="caption"
          onClick={(e) => handleOpenAth(e)}
          sx={{mr: 1}}>
          {loading && <Skeleton width={125}/>}
          {!loading && !hide && aths?.overall?.eurValue && formatEur(aths?.overall?.eurValue)}
          {!loading && hide && '*** €'}
        </Typography>
      </Stack>
    </>
  )
}

export type DashboardNumbersItemProps = {
  coin: Coin
  amount: number
  coinInfo?: CoinInfoItem
  timeFrame: keyof CoinInfoItemDelta
  rate: number | undefined
  overallEur: number
  loading: boolean
  ath?: Ath
  hide: boolean
}

export const DashboardNumberItem = (
  {
    coin,
    amount,
    coinInfo,
    timeFrame,
    rate,
    loading,
    overallEur,
    ath,
    hide,
  }: DashboardNumbersItemProps
) => {
  const [open, setOpen] = useState<boolean>(false)
  const media = useMedia()

  const changeIndicators = (media.isSuperSmall) ? (
    <>
      <ChangeIndicator
        timeFrame={timeFrame as TimeFrame}
        type="percentage"
        value={coinInfo?.delta && coinInfo?.delta[timeFrame] !== null ? (coinInfo?.delta[timeFrame] - 1) * 100 : undefined}
        loading={loading}/>
      <ChangeIndicator
        timeFrame={TIME_FRAME_NEXT_MAP[timeFrame as TimeFrame]}
        type="percentage"
        value={
          coinInfo?.delta &&
          coinInfo?.delta[TIME_FRAME_NEXT_MAP[timeFrame as TimeFrame]] !== null ?
            (coinInfo?.delta[TIME_FRAME_NEXT_MAP[timeFrame as TimeFrame]] - 1) * 100 :
            undefined
        }
        loading={loading}/>
    </>
  ) : (
    <>
      {[TimeFrame.day, TimeFrame.week, TimeFrame.month, TimeFrame.year].map((t, index) => (
        <ChangeIndicator
          timeFrame={t}
          type="percentage"
          value={
            coinInfo?.delta &&
            coinInfo?.delta[timeFrame] !== null &&
            coinInfo?.delta[t] ?
              (coinInfo?.delta[t] - 1) * 100 :
              undefined
          }
          key={index}
          loading={loading}/>
      ))}
    </>
  )

  return (
    <Grid item>
      <Paper elevation={1} sx={{
        p: media.when<number>({
          onSuperSmall: () => 0,
          onSmall: () => 0.25,
          onMedium: () => 0.25,
          onBig: () => 0.25,
        }),
      }}>
        <Stack direction="row" alignItems="center" justifyContent="center" onClick={() => setOpen(!open)}>
          <IconButton disabled>
            <CoinImage coin={coin} height={media.when<number>({
              onSuperSmall: () => 30,
              onSmall: () => 35,
              onMedium: () => 40,
              onBig: () => 48,
            })}/>
          </IconButton>
          <Grid container>
            <Grid item>
              <Typography fontWeight="bold" noWrap fontSize={media.when<number>({
                onSuperSmall: () => 13,
                onSmall: () => 13,
                onMedium: () => 15,
                onBig: () => 17,
              })}>
                {loading && <Skeleton width={70}/>}
                {!loading && !hide && rate && formatEur(amount * rate)}
                {!loading && hide && '*** €'}
              </Typography>
              {Coin.eur !== coin && (
                <>
                  <Typography noWrap fontSize="small" unselectable="on">
                    {loading && <Skeleton width={80}/>}
                    {!loading && rate && `${formatCoin(rate)} €`}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
            {changeIndicators}
          </Box>
        </Stack>
        <Collapse in={open && coin !== Coin.eur}>
          <Grid container sx={{m: 1, mt: 0.5}} rowSpacing={1}>
            <NumbersItemText
              loading={loading}
              value={`${formatCoin(amount)} ${coin}`}
              hide={hide}
              description="Coins"/>
            <NumbersItemText
              loading={loading}
              hide={false}
              value={rate ? formatCoin(rate) + ' €' : ''}
              description="Price per Coin"/>
            {ath?.eurValue && (
              <>
                <NumbersItemText
                  loading={loading}
                  value={formatEur(ath.eurValue)}
                  hide={hide}
                  description="ATH"/>
                <NumbersItemText
                  loading={loading}
                  hide={false}
                  value={ath.time?.substring(0, 16)?.replace('T', ' ')}
                  description="ATH time"/>
              </>
            )}
            <NumbersItemText
              loading={loading}
              hide={false}
              value={formatPercentage((amount * (rate || 1) * 100) / overallEur)}
              description="Portfolio %"/>
          </Grid>
        </Collapse>
      </Paper>
    </Grid>
  )
}

type TimeFrameSelectionProps = {
  onChange: (newValue: TimeFrame) => void
}

const TIME_FRAME_NEXT_MAP: Record<TimeFrame, TimeFrame> = {
  hour: TimeFrame.day,
  day: TimeFrame.week,
  week: TimeFrame.month,
  month: TimeFrame.quarter,
  quarter: TimeFrame.year,
  year: TimeFrame.hour,
}

const TimeFrameSelection = ({onChange}: TimeFrameSelectionProps) => {
  const media = useMedia()
  const [timeFrame, setTimeFrame] = useState<TimeFrame>(TimeFrame.day)

  if (media.isSuperSmall) {
    return (
      <>
        <IconButton size="small" color="secondary" onClick={() => {
          const newTimeFrame = TIME_FRAME_NEXT_MAP[timeFrame]
          setTimeFrame(newTimeFrame)
          onChange(newTimeFrame)
        }}>
          <DateRange fontSize="small"/>
        </IconButton>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => {
            const newTimeFrame = TIME_FRAME_NEXT_MAP[timeFrame]
            setTimeFrame(newTimeFrame)
            onChange(newTimeFrame)
          }}>
          {timeFrame}
        </Button>
      </>
    )
  }
  return <></>
}
