import React, {useCallback, useEffect, useState} from 'react'
import {
  Coin,
  listIntradayCandlesForCoin,
  listIntradayCandlesForProfile,
  Valuation,
  ValuationCandles,
} from '../../../clients/WalletClient'
import {getCurrentProfile} from '../../../clients/ProfileClient'
import {Box, Grid, Skeleton, ToggleButton, ToggleButtonGroup} from '@mui/material'
import {DonutChart} from '../DonutChart'
import {CandleChart} from '../CandleChart'
import {useMedia} from '../../utils/useMedia'
import {useGetAccessToken} from '../../utils/getAccessToken'

export const DashboardStats = () => {
  const getAccessToken = useGetAccessToken()
  const [valuations, setValuations] = useState<ValuationCandles[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [value, setValue] = useState<'eur' | 'usd' | 'amount' | 'eurRate' | 'usdRate'>('eur')
  const [coin, setCoin] = useState<Coin>()
  const [amounts, setAmounts] = useState<{ [coin: string]: Valuation }>()
  const media = useMedia()

  const loadData = useCallback(async () => {
    setLoading(true)
    try {
      const accessToken = await getAccessToken()
      const response = coin ?
        await listIntradayCandlesForCoin(accessToken, coin) :
        await listIntradayCandlesForProfile(accessToken)
      const minDate = new Date().getTime() / 1000 - timeSpan
      setValuations(
        response.valuations
          .filter(it => it.time > minDate)
          .filter(it => it.coin !== Coin.eur),
      )

      const profile = await getCurrentProfile(accessToken)
      if (profile) {
        setAmounts(profile.amounts)
      }
    } finally {
      setLoading(false)
    }
  }, [setLoading, getAccessToken, setValuations, coin])

  useEffect(() => {
    loadData()
  }, [loadData])

  const donutContent =
    loading ? (
      <Skeleton width="100%" height={200} variant="rounded"/>
    ) : (amounts && (
      <DonutChart items={
        Object.entries(amounts)
          .filter(([c]) => c !== Coin.eur)
          .map(([coin, valuation]) => ({
            name: coin,
            value: getValue(valuation, value),
          }))
          .sort((i1, i2) => i2.value - i1.value)
      }/>
    ))

  const candleContent =
    loading ? (
      <Skeleton height={media.when<string>({
        onSuperSmall: () => '450px',
        onSmall: () => '500px',
        onMedium: () => '550px',
        onBig: () => '600px',
      })} width="100%" sx={{mt: -3}}></Skeleton>
    ) : (valuations ? (
      <>
        <Box sx={{pt: 2}}>
          <CandleChart
            timeUnit="hour"
            name={coin || 'All'}
            items={valuations?.map(i => ({
              close: getValue(i.close, value),
              high: getValue(i.high, value),
              low: getValue(i.low, value),
              open: getValue(i.open, value),
              time: i.time * 1000,
            })) || []}
          />
        </Box>
      </>
    ) : (
      <h3>No data found</h3>
    ))

  return (
    <>
      <Grid
        container
        spacing={1}
        width="100%"
        justifyContent="center"
      >
        <Grid item>
          <ToggleButtonGroup
            color="primary"
            value={value}
            exclusive
            onChange={(_, newValue) => setValue(newValue || 'eur')}
          >
            <ToggleButton value="eur">EUR</ToggleButton>
            <ToggleButton value="usd">USD</ToggleButton>
            <ToggleButton value="amount" disabled={!coin}>Amount</ToggleButton>
            <ToggleButton value="eurRate" disabled={!coin}>Rate (€)</ToggleButton>
            <ToggleButton value="usdRate" disabled={!coin}>Rate ($)</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            color="primary"
            value={coin}
            exclusive
            onChange={(_, newCoin) => {
              if (!newCoin) {
                setValue('eur')
              }
              setCoin(newCoin)
            }}>
            {
              amounts && Object.keys(amounts)
                .filter(it => it !== Coin.eur)
                .sort((a1, a2) => amounts[a2].eurValue - amounts[a1].eurValue)
                .map(c => (
                  <ToggleButton key={c} value={c}>{c}</ToggleButton>
                ))
            }
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      {candleContent}
      {!coin && donutContent}
    </>
  )
}

const getValue = (valuation: Valuation, value: 'eur' | 'usd' | 'amount' | 'eurRate' | 'usdRate') => {
  switch (value) {
    case 'amount':
      return valuation.amount
    case 'usd':
      return valuation.usdValue
    case 'eurRate':
      return valuation.eurRate
    case 'usdRate':
      return valuation.usdRate
    default:
      return valuation.eurValue
  }
}

const timeSpan = 60 * 60 * 30