import {BarChart, PieChart, TableChart} from '@mui/icons-material'
import {Box, Container, Grid, Paper, Stack, Typography, Zoom} from '@mui/material'
import React from 'react'
import {ToPlatformButton} from '../security/ToPlatformButton'
import {ImpressButton} from '../utils/ImpressButton'

export const LandingPage = () => {
  const logos = [
    'BTC.png',
    'SOL.png',
    'ETH.png',
    'USDC.png',
    'kraken.png',
    'Binance.png',
    'LINK.png',
    'Matic.png',
    'ORCA.png',
    'CRO.png',
    'JUP.png',
    'LFNTY.png',
    'ATLAS.png',
    'PYTH.png',
    'Livecoinwatch.png',
    'Coinmarketcap.png',
  ]
  return (
    <>
      <Container style={{maxWidth: 900, marginBottom: 50}}>
        <Box display='flex'>
          <Box margin='auto'>
            <img src="/logo.svg" style={{width: '100%', maxHeight: 250}} alt="logo"/>
          </Box>
        </Box>
        <Box display='flex'>
          <Box margin='auto'>
            <Paper elevation={1} sx={{padding: 4, marginTop: 2}}>
              <Box display='flex' style={{marginTop: 20}}>
                <Box margin='auto'>
                  <Typography variant='h4' style={{textAlign: 'center'}}>
                    Summing up crypto investments
                    <br/>
                    <BarChart/><PieChart/><TableChart/></Typography>
                </Box>
              </Box>
              <Box display='flex' style={{marginTop: 25}}>
                <Box margin='auto'>
                  <ToPlatformButton/>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
        <Grid container display='flex' spacing={5} justifyContent='center' style={{marginTop: 15}}>
          {logos.map((logo, i) => (
              <Grid key={i} item xs={3} sm={2} display='flex' justifyContent='center'>
                <Zoom in style={{margin: 20, height: 70, transitionDelay: `${i * 150}ms`}}>
                  <img src={logo} style={{height: 65}} alt="avatar"/>
                </Zoom>
              </Grid>
            )
          )}
        </Grid>
      </Container>
      <Paper
        sx={theme => ({
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          background: theme.palette.primary.main,
        })}
        elevation={3}>
        <Stack direction="row" justifyContent="end">
          <ImpressButton/>
        </Stack>
      </Paper>
    </>
  )
}