import {Route, Routes} from 'react-router-dom'
import {LandingPage} from './landing/LandingPage'
import {DashboardPage} from './dashboard/DashboardPage'
import {UserListPage} from './admin/UserListPage'
import React from 'react'
import {ProtectedRoute} from './security/ProtectedRoute'
import {SettingsPage} from './settings/SettingsPage'
import {NotFoundPage} from './utils/NotFoundPage'
import {ProfilePage} from './profile/ProfilePage'
import {SharePage} from './share/SharePage'

export const Router = () => {
  return (
    <Routes>
      <Route key="landing" path="/" index element={<LandingPage/>}/>
      <Route key="dashboard" path="/dashboard" element={
        <ProtectedRoute necessaryRole="user" redirectPath="/">
          <DashboardPage/>
        </ProtectedRoute>
      }/>
      <Route key="profile" path="/profile" element={
        <ProtectedRoute necessaryRole="user" redirectPath="/">
          <ProfilePage/>
        </ProtectedRoute>
      }/>
      <Route key="settings" path="/settings" element={
        <ProtectedRoute necessaryRole="user" redirectPath="/">
          <SettingsPage/>
        </ProtectedRoute>
      }/>
      <Route key="userlist" path="/admin/users" element={
        <ProtectedRoute necessaryRole="admin" redirectPath="/">
          <UserListPage/>
        </ProtectedRoute>
      }/>
      <Route key="share" path="/share/:id" element={
        <ProtectedRoute necessaryRole="user" redirectPath="/">
          <SharePage/>
        </ProtectedRoute>
      }/>
      <Route key="notfound" path="*" element={<NotFoundPage/>}/>
    </Routes>
  )
}