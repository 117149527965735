const locale = 'en-US'

export const formatEur = (number: number) => {
  return `${number.toLocaleString(locale, {maximumFractionDigits: 2, minimumFractionDigits: 2})} €`
}

export const formatPercentage = (number: number, maximumFractionDigits = 2) => {
  return `${number.toLocaleString(locale, {maximumFractionDigits, minimumFractionDigits: 0})} %`
}

export const roundRate = (rate: number) => {
  if (rate > 100000) {
    return round(rate, 0)
  } else if (rate > 100) {
    return round(rate, 2)
  } else if (rate > 1) {
    return round(rate, 4)
  } else if (rate > 0.1) {
    return round(rate, 6)
  } else {
    return round(rate, 9)
  }
}

export const round = (number: number, decimals = 2) => {
  if (decimals < 0) {
    throw new Error('can not round with negative decimals')
  }
  if (decimals === 0) {
    return Math.round(number)
  }
  const factor = Math.pow(10, decimals)
  return Math.round(number * factor) / factor
}

export const formatCoin = (amount: number) => {
  if (amount > 100000) {
    return amount.toLocaleString(locale, {maximumFractionDigits: 0})
  } else if (amount > 100) {
    return amount.toLocaleString(locale, {maximumFractionDigits: 2})
  } else if (amount > 1) {
    return amount.toLocaleString(locale, {maximumFractionDigits: 4})
  } else if (amount > 0.1) {
    return amount.toLocaleString(locale, {maximumFractionDigits: 5})
  } else {
    return amount.toLocaleString(locale, {maximumFractionDigits: 6})
  }
}

export const roundDown = (number: number, decimals: number) => {
  if (decimals < 0) {
    throw new Error('can not round with negative decimals')
  }
  if (decimals === 0) {
    return Math.floor(number)
  }
  const factor = Math.pow(10, decimals)
  return Math.floor(number * factor) / factor
}
