import {PageLayout} from '../PageLayout'
import {Button} from '@mui/material'
import {Home} from '@mui/icons-material'
import {useNavigate} from 'react-router-dom'
import React from 'react'

export const NotFoundPage = () => {
  const navigate = useNavigate()
  return (
    <PageLayout>
      <h1>Page not found</h1>
      <Button variant="contained" startIcon={<Home/>} onClick={() => navigate('/dashboard')}>Back home</Button>
    </PageLayout>
  )
}