import React, {useCallback, useState} from 'react'
import {
  Card,
  CardHeader,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import {CopyAll, DeleteForever, ExpandLess, ExpandMore, Grid3x3, Percent, TextFields} from '@mui/icons-material'
import {CoinImage} from '../../utils/CoinImage'
import {addToastMessage} from '../../../stores/MessageStore'
import {MessageSeverity} from '../../utils/MessageSnackbar'
import {useStringShortener} from '../../utils/useStringShortener'
import {useCopyToClipboard} from 'usehooks-ts'
import {deleteTradingPlan, TradingPlanItem} from '../../../clients/TradingClient'
import {ConfirmationButton} from '../../utils/ConfirmationButton'
import {useGetAccessToken} from '../../utils/getAccessToken'

export type TradingPlanCardProps = {
  savingsPlan: TradingPlanItem
  onReload: () => void
}

export const TradingPlanCard = (
  {
    savingsPlan: {coin, description, id, perBuyMax, smaDays},
    onReload,
  }: TradingPlanCardProps) => {
  const getAccessToken = useGetAccessToken()
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const {shorten} = useStringShortener()
  const [, copy] = useCopyToClipboard()

  const handleDelete = useCallback(async (id: string) => {
    setLoading(true)
    try {
      await deleteTradingPlan(await getAccessToken(), id)
      onReload()
      addToastMessage('Successfully deleted', MessageSeverity.success)
    } finally {
      setLoading(false)
    }
  }, [setLoading, getAccessToken, onReload])

  if (loading) {
    return <Skeleton height={250} width="100%"/>
  }

  return (
    <Card sx={{m: 0.5}}>
      <CardHeader
        onClick={(e: MouseEvent) => {
          if (!e.defaultPrevented) {
            setOpen(!open)
          }
        }}
        avatar={<CoinImage coin={coin}/>}
        title={<Typography textTransform="uppercase">{coin}</Typography>}
        subheader={description}
        action={
          loading ? (
              <Stack direction="row">
                <Skeleton variant="circular" animation="wave" width={36} height={36}/>
                <Skeleton variant="circular" animation="wave" width={36} height={36} sx={{ml: 2}}/>
              </Stack>
            ) :
            <>
              <ConfirmationButton
                button={{color: 'error'}}
                action={() => handleDelete(id)}
                actionName={`delete trading plan ${id}`}
              >
                <DeleteForever/>
              </ConfirmationButton>
              <IconButton>{open ? <ExpandLess/> : <ExpandMore/>}</IconButton>
            </>
        }
      />
      <Collapse in={open} unmountOnExit>
        <List>
          <ListItem>
            <ListItemIcon><Grid3x3/></ListItemIcon>
            <ListItemText primary={shorten(`#${id}`)} secondary="Id"/>
            <ListItemSecondaryAction>
              <IconButton onClick={() => {
                copy(id)
                addToastMessage('Successfully copied', MessageSeverity.success)
              }}>
                <CopyAll/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon><Percent/></ListItemIcon>
            <ListItemText primary={description} secondary="Description"/>
          </ListItem>
          <ListItem>
            <ListItemIcon><TextFields/></ListItemIcon>
            <ListItemText
              primary={`Buy when price gets over ${smaDays} moving average and sell if it gets below.`}
              secondary={`Maximum: ${perBuyMax}€`}/>
          </ListItem>
        </List>
      </Collapse>
    </Card>
  )
}
