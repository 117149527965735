import {useMedia} from './useMedia'

export const useStringShortener = () => {
  const media = useMedia()

  return {
    shorten: (input: string | undefined, bigMaxLength = 80, mediumMaxLength = 80, smallMaxLength = 32, superSmallMaxLength = 20) => {
      if (!input) {
        return ''
      }
      return media.when({
        onBig: () => input.length >= bigMaxLength ? input.substring(0, bigMaxLength) + '...' : input,
        onMedium: () => input.length >= mediumMaxLength ? input.substring(0, mediumMaxLength) + '...' : input,
        onSmall: () => input.length >= smallMaxLength ? input.substring(0, smallMaxLength) + '...' : input,
        onSuperSmall: () => {
          return input.length >= superSmallMaxLength ? input.substring(0, superSmallMaxLength) + '...' : input
        },
      })
    },
  }
}