import {Avatar} from '@mui/material'
import {Coin} from '../../clients/WalletClient'

export type CoinImageProps = {
  coin: string | Coin
  height?: number
}

export const CoinImage = ({coin, height}: CoinImageProps) => (
  <Avatar src={`/${coin}.png`} variant="rounded" sx={{height, width: height, m: 0, p: 0}} imgProps={{
    style: {
      height,
      objectFit: 'contain',
    },
  }}/>
)