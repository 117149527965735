import {Button, ButtonProps, CircularProgress} from '@mui/material'
import React, {useCallback, useState} from 'react'

export interface ActionButtonProps extends ButtonProps {
  buttonAction: () => Promise<void>
}

export const ActionButton = (props: ActionButtonProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const handleAction = useCallback(async () => {
    setLoading(true)
    try {
      await props.buttonAction()
    } finally {
      setLoading(false)
    }
  }, [setLoading, props])
  return (
    <Button
      {...props}
      onClick={handleAction}
      disabled={loading || props.disabled}
      startIcon={loading ? <CircularProgress size="1rem"/> : props.startIcon}/>
  )
}