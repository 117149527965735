import {PageLayout} from '../PageLayout'
import React from 'react'
import {AccountCircle, BarChart, ReceiptLong, TableChart} from '@mui/icons-material'
import {TabsContainer} from '../utils/TabsContainer'
import {DashboardNumbers} from './DashboardNumbers'
import {DashboardStats} from './components/DashboardStats'
import {FriendNumbers} from './FriendNumbers'
import {ExchangeInfo} from './ExchangeInfo'

export const DashboardPage = () => {
  return (
    <PageLayout>
      <TabsContainer tabs={[
        {
          label: 'numbers',
          key: 'numbers',
          icon: <TableChart/>,
          render: () => <DashboardNumbers/>,
        }, {
          label: 'stats',
          key: 'stats',
          icon: <BarChart/>,
          render: () => <DashboardStats/>,
        }, {
          label: 'trades',
          key: 'trades',
          icon: <ReceiptLong/>,
          render: () => <ExchangeInfo/>,
        }, {
          label: 'friends',
          key: 'friends',
          icon: <AccountCircle/>,
          render: () => <FriendNumbers/>,
        },
      ]}/>
    </PageLayout>
  )
}