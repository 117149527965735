import * as React from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {Button} from '@mui/material'
import {Login, SpaceDashboard} from '@mui/icons-material'
import {useNavigate} from 'react-router-dom'
import {getRequiredEnvVariable} from '../utils/getRequiredEnvVariable'

const domain = getRequiredEnvVariable('REACT_APP_DOMAIN')

export const ToPlatformButton = () => {
  const {loginWithRedirect, user, isAuthenticated, error} = useAuth0()
  const navigate = useNavigate()
  const isLoggedIn = !error && user && isAuthenticated
  const action = isLoggedIn ?
    () => navigate('/dashboard') :
    () => loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${domain}/dashboard`,
      },
    })
  const buttonText = isLoggedIn ? 'To dashboard' : 'Login'
  const icon = isLoggedIn ? <SpaceDashboard/> : <Login/>
  return (
    <Button
      variant='contained'
      onClick={action}
      startIcon={icon}
    >
      {buttonText}
    </Button>
  )
}