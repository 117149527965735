import {Box, Skeleton, Typography} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useGetAccessToken} from '../utils/getAccessToken'
import {listTradeHistory, TradeHistoryItem} from '../../clients/ExchangeClient'
import {range} from '../utils/range'
import {TradeHistoryCard} from './components/TradeHistoryCard'
import {KrakenInfoView} from './components/KrakenInfoView'
import {CoinInfoItem, loadCoinInfos} from '../../clients/RatesClient'

export const ExchangeInfo = () => {
  const getAccessToken = useGetAccessToken()
  const [loading, setLoading] = useState<boolean>(true)
  const [trades, setTrades] = useState<TradeHistoryItem[]>([])
  const [quotes, setQuotes] = useState<CoinInfoItem[]>([])

  const loadData = useCallback(async () => {
    setLoading(true)
    try {
      const accessToken = await getAccessToken()
      const response = await listTradeHistory(accessToken)
      setTrades(response.trades)
      const quoteResponse = await loadCoinInfos(accessToken, response.trades.map(it => it.coin))
      setQuotes(quoteResponse)
    } finally {
      setLoading(false)
    }
  }, [setLoading, getAccessToken, setTrades, setQuotes])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <>
      <Box sx={{height: 15, width: 20}}/>
      {loading && range(5).map(((s, i) => (
        <Skeleton height={80} key={i}/>
      )))}
      {!loading && (
        <>
          <Typography variant="h3" fontWeight="bold">Coins</Typography>
          <Box sx={{height: 15, width: 20}}/>
          <KrakenInfoView trades={trades} coinInfos={quotes}/>
          <Box sx={{height: 15, width: 20}}/>
          <Typography variant="h3" fontWeight="bold">Single trades</Typography>
          <Box sx={{height: 15, width: 20}}/>
          {trades && trades.map(t => <TradeHistoryCard key={t.txId + t.time} trade={t}/>)}
          <Box sx={{height: 15, width: 20}}/>
        </>
      )}
    </>
  )
}

