import {
  Blockchain,
  ChainWalletData,
  deleteWallet,
  StaticWalletData,
  updateWalletAmount,
  updateWalletDescription,
  WalletItem,
  WalletType,
} from '../../clients/WalletClient'
import React, {ReactElement, useCallback, useState} from 'react'
import {
  Box,
  Card,
  CardHeader,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  TextField,
} from '@mui/material'
import {
  AlternateEmail,
  CopyAll,
  CurrencyExchange,
  DeleteForever,
  Description,
  Edit,
  EditOff,
  ExpandLess,
  ExpandMore,
  Grid3x3,
  OpenInBrowser,
  Paid,
  Save,
} from '@mui/icons-material'
import {useCopyToClipboard} from 'usehooks-ts'
import {addToastMessage} from '../../stores/MessageStore'
import {MessageSeverity} from '../utils/MessageSnackbar'
import {CoinImage} from '../utils/CoinImage'
import {NumberInput} from '../utils/NumberInput'
import {useStringShortener} from '../utils/useStringShortener'
import {ConfirmationButton} from '../utils/ConfirmationButton'
import {useGetAccessToken} from '../utils/getAccessToken'

export type WalletListItemProps = {
  wallet: WalletItem
  onReload: () => void
}

type ItemProps = {
  wallet: WalletItem
  onDelete: () => void
  onReload: () => void
}

export const WalletCard = ({wallet, onReload}: WalletListItemProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const getAccessToken = useGetAccessToken()

  const onDelete = useCallback(async () => {
    setLoading(true)
    try {
      await deleteWallet(await getAccessToken(), wallet.id)
      addToastMessage('Deleted successfully', MessageSeverity.success)
      onReload()
    } finally {
      setLoading(false)
    }
  }, [getAccessToken, wallet, setLoading, onReload])

  if (loading) {
    return <Skeleton width="100%" height={60}/>
  }

  const card: Record<WalletType, ReactElement> = {
    [WalletType.static]: <StaticWalletCard wallet={wallet} onDelete={onDelete} onReload={onReload}/>,
    [WalletType.blockchain]: <ChainWalletCard wallet={wallet} onDelete={onDelete} onReload={onReload}/>,
  }
  return card[wallet.type]
}

export const ChainWalletCard = ({wallet: {id, description, data}, onDelete, onReload}: ItemProps) => {
  const [, copy] = useCopyToClipboard()
  const getAccessToken = useGetAccessToken()
  const [open, setOpen] = useState<boolean>(false)
  const {shorten} = useStringShortener()
  const [loading, setLoading] = useState<boolean>(false)
  const [newDescription, setNewDescription] = useState<string>(description)
  const [descriptionOpen, setDescriptionOpen] = useState<boolean>(false)
  const mappedData = data as ChainWalletData

  const openSolanaInNewTab = (address: string) => {
    window.open(`https://explorer.solana.com/address/${address}`, '_blank', 'noreferrer')
  }

  const openBitcoinInNewTab = (address: string) => {
    window.open(`https://www.blockchain.com/explorer/addresses/btc/${address}`, '_blank', 'noreferrer')
  }
  const openEthereumInNewTab = (address: string) => {
    window.open(`https://etherscan.io/address/${address}`, '_blank', 'noreferrer')
  }

  const handleSaveNewDescription = useCallback(async () => {
    setLoading(true)
    try {
      await updateWalletDescription(await getAccessToken(), id, newDescription)
      addToastMessage('Successfully updated', MessageSeverity.success)
      onReload()
    } finally {
      setLoading(false)
    }
  }, [newDescription, setLoading, onReload, getAccessToken, id])

  return (
    <Card sx={{width: '100%', m: 0.5}}>
      <CardHeader
        onClick={() => setOpen(!open)}
        avatar={<CoinImage coin={mappedData.coin}/>}
        title={`${mappedData.chain} wallet (${mappedData.coin})`}
        subheader={description}
        action={
          <>
            <ConfirmationButton
              button={{disabled: loading, color: 'error'}}
              action={onDelete}
              actionName={`delete wallet ${id}`}
            >
              {loading && <Skeleton variant="circular" width={32} height={32}/>}
              {!loading && <DeleteForever/>}
            </ConfirmationButton>
            <IconButton disabled={loading}>
              {loading && <Skeleton variant="circular" sx={{ml: -1}} width={32} height={32}/>}
              {!loading && (open ? <ExpandLess/> : <ExpandMore/>)}
            </IconButton>
          </>
        }
      />
      <Collapse in={open} unmountOnExit>
        {loading && (
          <>
            <Skeleton sx={{ml: 3, mr: 3}} height={80}/>
            <Skeleton sx={{ml: 3, mr: 3}} height={80}/>
            <Skeleton sx={{ml: 3, mr: 3}} height={80}/>
            <Skeleton sx={{ml: 3, mr: 3}} height={80}/>
          </>
        )}
        {!loading && (
          <List>
            <ListItem>
              <ListItemIcon><Grid3x3/></ListItemIcon>
              <ListItemText primary={shorten(`#${id}`)} secondary="Id"/>
              <ListItemSecondaryAction>
                <IconButton onClick={() => {
                  copy(id)
                  addToastMessage('Successfully copied', MessageSeverity.success)
                }}>
                  <CopyAll/>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemIcon><Description/></ListItemIcon>
              <ListItemText primary={description} secondary="Description"/>
              <ListItemSecondaryAction>
                <IconButton onClick={() => setDescriptionOpen(!descriptionOpen)}>
                  {descriptionOpen ? <EditOff/> : <Edit/>}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={descriptionOpen}>
              <Box sx={{m: 3, pl: 6}}>
                <TextField
                  autoFocus
                  margin="dense"
                  label="New description"
                  value={newDescription}
                  fullWidth
                  multiline
                  variant="standard"
                  onChange={e => setNewDescription(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleSaveNewDescription}>
                        <Save/>
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            </Collapse>
            <ListItem>
              <ListItemIcon><CurrencyExchange/></ListItemIcon>
              <ListItemText primary={mappedData.chain} secondary="Blockchain"/>
            </ListItem>
            <ListItem>
              <ListItemIcon><AlternateEmail/></ListItemIcon>
              <ListItemText primary={shorten(mappedData.address, 23)} secondary="Address"/>
              <ListItemSecondaryAction>
                {mappedData.chain === Blockchain.solana && (
                  <IconButton
                    onClick={() => openSolanaInNewTab(mappedData.address)}>
                    <OpenInBrowser/>
                  </IconButton>
                )}
                {mappedData.chain === Blockchain.bitcoin && (
                  <IconButton
                    onClick={() => openBitcoinInNewTab(mappedData.address)}>
                    <OpenInBrowser/>
                  </IconButton>
                )}
                {mappedData.chain === Blockchain.ethereum && (
                  <IconButton
                    onClick={() => openEthereumInNewTab(mappedData.address)}>
                    <OpenInBrowser/>
                  </IconButton>
                )}
                <IconButton onClick={() => {
                  copy(mappedData.address)
                  addToastMessage('Successfully copied', MessageSeverity.success)
                }}>
                  <CopyAll/>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        )}
      </Collapse>
    </Card>
  )
}

export const StaticWalletCard = ({wallet: {id, description, data}, onDelete, onReload}: ItemProps) => {
  const [, copy] = useCopyToClipboard()
  const getAccessToken = useGetAccessToken()
  const [open, setOpen] = useState<boolean>(false)
  const [descriptionOpen, setDescriptionOpen] = useState<boolean>(false)
  const [amountOpen, setAmountOpen] = useState<boolean>(false)
  const mappedData = data as StaticWalletData
  const [newAmount, setNewAmount] = useState<number | undefined>(mappedData.amount)
  const [newDescription, setNewDescription] = useState<string>(description)
  const [loading, setLoading] = useState<boolean>(false)
  const {shorten} = useStringShortener()

  const handleSaveNewDescription = useCallback(async () => {
    setLoading(true)
    try {
      await updateWalletDescription(await getAccessToken(), id, newDescription)
      addToastMessage('Successfully updated', MessageSeverity.success)
      onReload()
    } finally {
      setLoading(false)
    }
  }, [newDescription, setLoading, onReload, getAccessToken, id])

  const handleSaveNewAmount = useCallback(async () => {
    if (!newAmount) {
      addToastMessage('Invalid amount', MessageSeverity.error)
      return
    }
    setLoading(true)
    try {
      await updateWalletAmount(await getAccessToken(), id, newAmount)
      addToastMessage('Successfully updated', MessageSeverity.success)
      onReload()
    } finally {
      setLoading(false)
    }
  }, [newAmount, setLoading, onReload, getAccessToken, id])

  return (
    <Card sx={{width: '100%', m: 0.5}}>
      <CardHeader
        onClick={() => setOpen(!open)}
        avatar={<CoinImage coin={mappedData.coin}/>}
        title={`${mappedData.coin} coins`}
        subheader={description}
        action={
          <>
            <ConfirmationButton
              button={{color: 'error', disabled: loading}}
              action={onDelete}
              actionName={`delete wallet ${id}`}
            >
              {loading && <Skeleton variant="circular" width={32} height={32}/>}
              {!loading && <DeleteForever/>}
            </ConfirmationButton>
            <IconButton disabled={loading}>
              {loading && <Skeleton variant="circular" sx={{ml: -1}} width={32} height={32}/>}
              {!loading && (open ? <ExpandLess/> : <ExpandMore/>)}
            </IconButton>
          </>
        }
      />
      <Collapse in={open} unmountOnExit>
        {loading && (
          <>
            <Skeleton sx={{ml: 3, mr: 3}} height={80}/>
            <Skeleton sx={{ml: 3, mr: 3}} height={80}/>
            <Skeleton sx={{ml: 3, mr: 3}} height={80}/>
            <Skeleton sx={{ml: 3, mr: 3}} height={80}/>
          </>
        )}
        {!loading && (
          <List>
            <ListItem>
              <ListItemIcon><Grid3x3/></ListItemIcon>
              <ListItemText primary={shorten(`#${id}`)}
                            secondary="Id"/>
              <ListItemSecondaryAction>
                <IconButton onClick={() => {
                  copy(id)
                  addToastMessage('Successfully copied', MessageSeverity.success)
                }}>
                  <CopyAll/>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemIcon><Description/></ListItemIcon>
              <ListItemText primary={description} secondary="Description"/>
              <ListItemSecondaryAction>
                <IconButton onClick={() => setDescriptionOpen(!descriptionOpen)}>
                  {descriptionOpen ? <EditOff/> : <Edit/>}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={descriptionOpen}>
              <Box sx={{m: 3, pl: 6}}>
                <TextField
                  autoFocus
                  margin="dense"
                  label="New description"
                  value={newDescription}
                  fullWidth
                  multiline
                  variant="standard"
                  onChange={e => setNewDescription(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleSaveNewDescription}>
                        <Save/>
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            </Collapse>
            <ListItem>
              <ListItemIcon><CurrencyExchange/></ListItemIcon>
              <ListItemText primary={mappedData.coin} secondary="Coin"/>
            </ListItem>
            <ListItem>
              <ListItemIcon><Paid/></ListItemIcon>
              <ListItemText primary={`${mappedData.amount} ${mappedData.coin}`} secondary="Amount"/>
              <ListItemSecondaryAction>
                <IconButton onClick={() => setAmountOpen(!amountOpen)}>
                  {amountOpen ? <EditOff/> : <Edit/>}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={amountOpen}>
              <Box sx={{m: 3, pl: 6}}>
                <NumberInput
                  autoFocus
                  margin="dense"
                  label="New amount"
                  value={newAmount}
                  fullWidth
                  multiline
                  variant="standard"
                  onChange={newNumber => {
                    setNewAmount(newNumber)
                  }}
                  InputProps={{
                    type: 'number',
                    endAdornment: (
                      <IconButton onClick={handleSaveNewAmount}>
                        <Save/>
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            </Collapse>
          </List>
        )}
      </Collapse>
    </Card>
  )
}