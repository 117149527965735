import {addToastMessage} from '../stores/MessageStore'
import {MessageSeverity} from '../components/utils/MessageSnackbar'

export async function fetchInternally<T>(call: () => Promise<Response>): Promise<T> {
  const response = await (call())
    .then(r => {
      if (r.status === 408) {
        const message = 'Request timed out. Please try again.'
        addToastMessage(message, MessageSeverity.warning)
        throw new Error(message)
      }
      if (!r.ok) {
        const message = `request failed with ${r.status} ${r.statusText}`
        addToastMessage(message, MessageSeverity.error)
        throw new Error(message)
      }
      return r
    })
    .then(r => r.json())
  return response as T
}