import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import {ThemeProvider} from './components/ThemeProvider'
import {BrowserRouter} from 'react-router-dom'
import {Auth0ProviderWithHistory} from './components/security/Auth0ProviderWithHistory'
import {Router} from './components/Router'
import {MessageSnackbar} from './components/utils/MessageSnackbar'

if (process.env.REACT_APP_VERSION) {
  // eslint-disable-next-line no-console
  console.log(
    '############################\n' +
    '#  _      ___\n' +
    '# | |__  / _ \\ _ __   ___\n' +
    "# | '_ \\| | | | '_ \\ / _ \\\n" +
    '# | | | | |_| | |_) |  __/\n' +
    '# |_| |_|\\___/| .__/ \\___|\n' +
    '#             |_|\n' +
    `# version: ${process.env.REACT_APP_VERSION}\n` +
    `# time: ${process.env.REACT_APP_DEPLOYMENT_TIME}\n` +
    '############################\n',
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <Router/>
          <MessageSnackbar/>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
