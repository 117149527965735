import {PageLayout} from '../PageLayout'
import {useNavigate, useParams} from 'react-router-dom'
import {useCallback, useEffect} from 'react'
import {addShareId} from '../../clients/ProfileClient'
import {useGetAccessToken} from '../utils/getAccessToken'
import {Typography} from '@mui/material'

export const SharePage = () => {
  const params = useParams()
  const getAccessToken = useGetAccessToken()
  const navigate = useNavigate()

  const addShareLink = useCallback(async () => {
    const accessToken = await getAccessToken()
    await addShareId(accessToken, params['id']!)
    setTimeout(() => {
      navigate('/dashboard?tab=friends')
    }, 3500)
  }, [params])

  useEffect(() => {
    addShareLink()
  }, [addShareLink])

  return (
    <PageLayout>
      <Typography variant="h1">Adding share link.</Typography>
      <Typography variant="h2">Please wait...</Typography>
    </PageLayout>
  )
}