import React, {PropsWithChildren} from 'react'
import {Auth0Provider} from '@auth0/auth0-react'
import {getRequiredEnvVariable} from '../utils/getRequiredEnvVariable'

export const Auth0ProviderWithHistory = ({children}: PropsWithChildren) => {
  const domain = getRequiredEnvVariable('REACT_APP_AUTH0_DOMAIN')
  const clientId = getRequiredEnvVariable('REACT_APP_AUTH0_CLIENT_ID')
  const appDomain = getRequiredEnvVariable('REACT_APP_DOMAIN')
  const redirectUri = `${appDomain}/dashboard`

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      cacheLocation='localstorage'
      useRefreshTokens={true}
      authorizationParams={{
        audience: 'https://h0pe.s0na.de',
        redirect_uri: redirectUri,
        scope: 'openid profile email offline_access',
      }}
    >
      {children}
    </Auth0Provider>
  )
}