import React from 'react'
import {Box, Button, Container, Typography} from '@mui/material'
import {Headline} from '../utils/Headline'
import {ArrowForward} from '@mui/icons-material'
import {useNavigate} from 'react-router-dom'

export const EmptyDashboardInfo = () => {
  const navigate = useNavigate()
  return (
    <Container style={{maxWidth: 750}}>
      <Headline text="Empty profile"/>
      <Box sx={{mt: 2}}>
        <Typography sx={{ml: 0.5}}>
          No data found. Please setup a wallet or an exchange.
        </Typography>
        <Typography sx={{ml: 0.5}}>
          New data may take up to 15 minutes to be shown here.
        </Typography>
        <Button
          sx={{mt: 3, ml: 0.5}}
          variant="contained"
          color="primary"
          endIcon={<ArrowForward/>}
          onClick={() => navigate('/settings?tab=wallets')}
        >
          Setup a wallet
        </Button>
        <Button
          sx={{mt: 3, ml: 1}}
          variant="contained"
          color="primary"
          endIcon={<ArrowForward/>}
          onClick={() => navigate('/settings?tab=exchanges')}
        >
          Setup an exchange
        </Button>
      </Box>
    </Container>
  )
}