import {buildAccessTokenHeader} from './clientUtils'
import {Coin} from './WalletClient'

const apiDomain = process.env.REACT_APP_API_DOMAIN

export const getSma = async (accessToken: string, coin: Coin, smaDays = 200) => {
  const response = await fetch(`${apiDomain}/trading/sma/${coin}?days=${smaDays}`, {
    headers: buildAccessTokenHeader(accessToken),
  })
  if (response.ok) {
    return (await response.json() as { sma: number | undefined, days: number })
  }
}

export const listTradingPlans = async (accessToken: string) => {
  const response = await fetch(`${apiDomain}/tradingPlans`, {
    headers: buildAccessTokenHeader(accessToken),
  })
  if (response.ok) {
    return (await response.json()) as { tradingPlans: TradingPlanItem[] }
  }
}

export const createTradingPlan = async (accessToken: string, request: TradingPlanRequest) => {
  await fetch(`${apiDomain}/tradingPlans`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'POST',
    body: JSON.stringify(request),
  })
}

export const deleteTradingPlan = async (accessToken: string, id: string) => {
  await fetch(`${apiDomain}/tradingPlans/${id}`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'DELETE',
  })
}

export type TradingPlanItem = {
  id: string
  coin: Coin
  sub: string
  smaDays: number
  description: string | undefined
  perBuyMax: number | undefined
  updated: string
  created: string
}

export type TradingPlanRequest = {
  coin: Coin
  smaDays: number
  description: string | undefined
  perBuyMax: number | undefined
}
