import {Typography} from '@mui/material'
import React from 'react'

export type HeadlineProps = {
  text: string | undefined
}

export const Headline = ({text}: HeadlineProps) => (
  <Typography
    fontSize={20}
    fontWeight="bold"
    sx={{ml: 0.5, textTransform: 'uppercase'}}
  >
    {text}
  </Typography>
)