import {PageLayout} from '../PageLayout'
import React from 'react'
import {ProfileSettings} from './ProfileSettings'
import {Container} from '@mui/material'

export const ProfilePage = () => {
  return (
    <PageLayout>
      <Container style={{maxWidth: 800}}>
        <ProfileSettings/>
      </Container>
    </PageLayout>
  )
}