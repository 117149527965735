import {Box, Skeleton, Typography, useTheme} from '@mui/material'
import {ArrowDropDown, ArrowDropUp, Remove} from '@mui/icons-material'
import {formatEur, formatPercentage} from '../utils/round'
import React from 'react'
import {TimeFrame} from '../utils/TimeFrame'
import {useMedia} from '../utils/useMedia'

export type ChangeIndicatorProps = {
  type: 'percentage' | 'absolute'
  value?: number
  timeFrame: TimeFrame
  loading?: boolean
}

export const ChangeIndicator = ({type, value, timeFrame, loading = false}: ChangeIndicatorProps) => {
  const theme = useTheme()
  const media = useMedia()
  let color = 'black'
  if (value !== undefined && value > 0) {
    color = theme.palette.success.light
  } else if (value !== undefined && value < 0) {
    color = theme.palette.error.light
  }
  const text = value !== undefined ? (type === 'percentage' ? formatPercentage(value, 1) : formatEur(value)) : ''
  return (
    <Box sx={{
      mr: 1,
      width: media.when<number>({
        onSuperSmall: () => 70,
        onSmall: () => 65,
        onMedium: () => 90,
        onBig: () => 100,
      }),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    }}>
      <Typography
        noWrap
        fontSize={media.when<number>({
          onSuperSmall: () => 14,
          onSmall: () => 13,
          onMedium: () => 15,
          onBig: () => 17,
        })}
        sx={{
          mr: 0.5,
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          color,
        }}>
        {loading && <Skeleton width={65}/>}
        {!loading && !!value && value > 0 && <ArrowDropUp fontSize="small"/>}
        {!loading && (value === 0 || !value) && <Remove fontSize="small"/>}
        {!loading && !!value && value < 0 && <ArrowDropDown fontSize="small"/>}
        {!loading && text}
      </Typography>
      <Typography noWrap fontSize={media.when<number>({
        onSuperSmall: () => 11,
        onSmall: () => 12,
        onMedium: () => 13,
        onBig: () => 14,
      })}>
        {loading && <Skeleton width={18}/>}
        {!loading && timeFrame}
      </Typography>
    </Box>
  )
}
