import {Box, Grid, Skeleton, Typography} from '@mui/material'
import React from 'react'

export type NumbersItemTextProps = {
  loading: boolean
  value: string | undefined
  description: string
  hide: boolean
}

export const NumbersItemText = ({loading, value, description, hide}: NumbersItemTextProps) => {
  return (
    <Grid item xs={6}>
      <Box textAlign="center" alignContent="center">
        {loading && <Skeleton sx={{m: 'auto'}} width={85}/>}
        {!loading && !hide && <Typography fontWeight="bold">{value}</Typography>}
        {!loading && hide && <Typography fontWeight="bold">*** €</Typography>}
        {<Typography variant="caption">{description}</Typography>}
      </Box>
    </Grid>
  )
}