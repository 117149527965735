import {Coin, Valuation} from '../clients/WalletClient'

export interface ProfileListItem {
  sub: string // partition key
  displayName?: string
  name: string
  email: string
  picture: string
}

export interface Profile extends ProfileListItem {
  roles: string[]
  currency: Currency
  created: string
  updated: string
  amounts?: { [coin: string]: Valuation }
  valuations?: { [coin: string]: Valuation }
  aths?: Aths
  autoHide: boolean
  sharingWith: string[]
  sharedWith: string[]
}

export interface SharedProfile {
  sub: string
  displayName: string
  picture: string
  amounts?: { [coin: string]: Valuation }
  aths?: Aths
}

export interface ProfileList {
  total: number
  page: number
  profiles: ProfileListItem[]
}

export enum Role {
  admin = 'admin',
  user = 'user',
}

export type CoinAmount = {
  amount: number
  coin: Coin
}

export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
}

export interface Aths {
  overall?: Ath
  coins: Record<Coin, Ath>
}

export interface Ath {
  eurValue?: number
  time?: string
}
