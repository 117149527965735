import {useLayoutEffect} from 'react'
import {percent, Root, Tooltip} from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import {
  AxisRendererX,
  AxisRendererY,
  CandlestickSeries,
  GaplessDateAxis,
  ValueAxis,
  XYCursor,
} from '@amcharts/amcharts5/xy'
import {StockChart, StockLegend, StockPanel, StockToolbar} from '@amcharts/amcharts5/stock'
import {Info} from '@mui/icons-material'
import {useMedia} from '../utils/useMedia'

export type CandleChartProps = {
  name: string
  timeUnit: 'day' | 'hour'
  timeUnitMultiplier?: number
  items: {
    time: number
    open: number
    close: number
    high: number
    low: number
  }[]
}

const format = '#.###,00'

export const CandleChart = ({name, timeUnit, timeUnitMultiplier = 1, items}: CandleChartProps) => {
  const media = useMedia()
  useLayoutEffect(() => {
    if (!items.length) {
      return
    }
    const root = Root.new('chartdiv')

    root.setThemes([
      am5themes_Animated.new(root),
    ])

    const chart = root.container.children.push(
      StockChart.new(root, {})
    )

    root.numberFormatter.set('numberFormat', format)

    const mainPanel = chart.panels.push(
      StockPanel.new(root, {
        wheelY: 'zoomX',
        panX: true,
        panY: true,
        height: percent(60),
      }),
    )

    const valueAxis = mainPanel.yAxes.push(
      ValueAxis.new(root, {
        renderer: AxisRendererY.new(root, {
          pan: 'zoom',
        }),
        tooltip: Tooltip.new(root, {}),
        numberFormat: format,
        extraTooltipPrecision: 2,
      }),
    )

    const dateAxis = mainPanel.xAxes.push(
      GaplessDateAxis.new(root, {
        baseInterval: {
          timeUnit,
          count: timeUnitMultiplier,
        },
        groupData: true,
        renderer: AxisRendererX.new(root, {}),
        tooltip: Tooltip.new(root, {}),
      }),
    )

    const valueSeries = mainPanel.series.push(
      CandlestickSeries.new(root, {
        name,
        valueXField: 'time',
        valueYField: 'close',
        highValueYField: 'high',
        lowValueYField: 'low',
        openValueYField: 'open',
        calculateAggregates: true,
        xAxis: dateAxis,
        yAxis: valueAxis,
        legendValueText: '{valueY}',
      }),
    )

    chart.set('stockSeries', valueSeries)

    const valueLegend = mainPanel.plotContainer.children.push(
      StockLegend.new(root, {
        stockChart: chart,
      }),
    )
    valueLegend.data.setAll([valueSeries])

    mainPanel.set(
      'cursor',
      XYCursor.new(root, {
        yAxis: valueAxis,
        xAxis: dateAxis,
        snapToSeries: [valueSeries],
        snapToSeriesBy: 'y!',
      })
    )

    valueSeries.data.setAll(items)

    const container = document.getElementById('chartcontrols')
    if (container) {
      StockToolbar.new(root, {
        container,
        stockChart: chart,
        controls: [],
      })
    }

    return () => {
      root.dispose()
    }
  }, [items, name, timeUnit, timeUnitMultiplier])

  if (!items.length) {
    return <div>
      <h3><Info/>&nbsp;Such emptiness</h3>
    </div>
  }

  return (
    <div>
      <div
        id="chartcontrols"
        style={{height: 'auto', padding: '5px 5px 0 15px'}}
      />
      <div id="chartdiv" style={{
        width: '100%',
        height: media.when<string>({
          onSuperSmall: () => '350px',
          onSmall: () => '400px',
          onMedium: () => '450px',
          onBig: () => '500px',
        }),
      }}></div>
    </div>
  )
}