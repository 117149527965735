export function requireNotNull<T>(param: T, fieldName: string): NonNullable<T> {
  checkNotNull(param, fieldName)
  return param
}

export function checkNotNull<T>(param: T, fieldName: string): asserts param is NonNullable<T> {
  if (param === null || param === undefined) {
    throw new Error(`value of ${fieldName} was required to be not null or undefined`)
  }
}
