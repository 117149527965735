import {GetTokenSilentlyOptions, GetTokenSilentlyVerboseResponse} from '@auth0/auth0-spa-js'
import * as jose from 'jose'
import {useAuth0} from '@auth0/auth0-react'
import {useCallback} from 'react'
import {AppState} from '@auth0/auth0-react/src/auth0-provider'
import {RedirectLoginOptions} from '@auth0/auth0-react/src/auth0-context'
import {getRequiredEnvVariable} from './getRequiredEnvVariable'

const domain = getRequiredEnvVariable('REACT_APP_DOMAIN')

export const getAccessToken = async (
  getAccessTokenSilently: {
    (
      options: GetTokenSilentlyOptions & { detailedResponse: true }
    ): Promise<GetTokenSilentlyVerboseResponse>;
    (options?: GetTokenSilentlyOptions): Promise<string>;
    (options: GetTokenSilentlyOptions): Promise<
      GetTokenSilentlyVerboseResponse | string
    >;
  },
  loginWithRedirect: (
    options?: RedirectLoginOptions<AppState>
  ) => Promise<void>
) => {
  try {
    const response = await getAccessTokenSilently({detailedResponse: true})
    const payload = jose.decodeJwt(response.access_token)
    const expiresAt = payload.exp
    if (!expiresAt || expiresAt - 5 * 60 * 60 > new Date().getTime() / 1000) {
      return response.access_token
    }
    // eslint-disable-next-line no-console
    console.info('Token is expired. Refresh.')
    return getAccessTokenSilently({cacheMode: 'off'})
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Failed to fetch token', {error: e})
    await loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${domain}/dashboard`,
      },
    })
    return ''
  }
}

export const useGetAccessToken = () => {
  const {getAccessTokenSilently, loginWithRedirect} = useAuth0()
  return useCallback(
    () => getAccessToken(getAccessTokenSilently, loginWithRedirect),
    [getAccessTokenSilently, loginWithRedirect]
  )
}