import {PageLayout} from '../PageLayout'
import React, {useCallback, useEffect, useState} from 'react'
import {Profile, ProfileListItem, Role} from '../types'
import {addRoleToUser, listProfiles, loadProfile, removeRoleFromUser} from '../../clients/ProfileClient'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Stack,
  Switch,
} from '@mui/material'
import {CopyAll, ExpandLess, ExpandMore} from '@mui/icons-material'
import {useCopyToClipboard} from 'usehooks-ts'
import {
  ChainWalletData,
  listWalletsForProfile,
  StaticWalletData,
  WalletItem,
  WalletResponse,
  WalletType,
} from '../../clients/WalletClient'
import {ExchangeResponse, listExchangesForProfile} from '../../clients/ExchangeClient'
import {addToastMessage} from '../../stores/MessageStore'
import {MessageSeverity} from '../utils/MessageSnackbar'
import {Headline} from '../utils/Headline'
import {formatCoin, formatEur} from '../utils/round'
import {CoinImage} from '../utils/CoinImage'
import {triggerLoad} from '../../clients/AdminClient'
import {ActionButton} from '../utils/ActionButton'
import {useStringShortener} from '../utils/useStringShortener'
import {useGetAccessToken} from '../utils/getAccessToken'

export const UserListPage = () => {
  const getAccessToken = useGetAccessToken()
  const [users, setUsers] = useState<ProfileListItem[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const handleTrigger = useCallback(async () => {
    const accessToken = await getAccessToken()
    await triggerLoad(accessToken)
  }, [getAccessToken])

  const loadProfileData = useCallback(async () => {
    setLoading(true)
    try {
      const accessToken = await getAccessToken()
      const profiles = await listProfiles(accessToken)
      if (profiles) {
        setUsers(profiles.profiles)
      }
    } finally {
      setLoading(false)
    }
  }, [setUsers, getAccessToken, setLoading])

  useEffect(() => {
    loadProfileData()
  }, [loadProfileData])

  return (
    <PageLayout>
      <Container style={{maxWidth: 800}}>
        <Stack direction="row" alignItems="end" justifyContent="stretch" sx={{ml: 0.5, mt: 0}}>
          <Headline text="Users"/>
          <Box flex={1}/>
          <ActionButton size="small"
                        variant="outlined"
                        sx={{mr: 1}}
                        buttonAction={handleTrigger}
          >
            load
          </ActionButton>
        </Stack>
        {loading && (
          <>
            <Skeleton sx={{mb: -3}} height={100} width="100%"/>
            <Skeleton sx={{mb: -3}} height={100} width="100%"/>
            <Skeleton sx={{mb: -3}} height={100} width="100%"/>
            <Skeleton sx={{mb: -3}} height={100} width="100%"/>
          </>
        )}
        {!loading && users.map(u => <ProfileCard key={u.sub} profile={u}/>)}
      </Container>
    </PageLayout>
  )
}

interface ProfileCardProps {
  profile: ProfileListItem
}

const ProfileCard = ({profile}: ProfileCardProps) => {
  const [, copy] = useCopyToClipboard()
  const getAccessToken = useGetAccessToken()
  const [expanded, setExpanded] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [profileDetails, setProfileDetails] = useState<Profile | undefined>()
  const [rolesOpen, setRolesOpen] = useState<boolean>(false)
  const [wallets, setWallets] = useState<WalletResponse>()
  const [walletsOpen, setWalletsOpen] = useState<boolean>(false)
  const [exchanges, setExchanges] = useState<ExchangeResponse>()
  const [exchangesOpen, setExchangesOpen] = useState<boolean>(false)
  const [amountsOpen, setAmountsOpen] = useState<boolean>(false)
  const {shorten} = useStringShortener()

  const loadData = useCallback(async () => {
    setLoading(true)
    try {
      const accessToken = await getAccessToken()
      const response = await loadProfile(accessToken, profile.sub)
      setProfileDetails(response)
      const wallets = await listWalletsForProfile(accessToken, profile.sub)
      setWallets(wallets)
      const exchanges = await listExchangesForProfile(accessToken, profile.sub)
      setExchanges(exchanges)
    } catch (e) {
      setExpanded(false)
    } finally {
      setLoading(false)
    }
  }, [setLoading, setProfileDetails, getAccessToken, profile.sub, setExpanded])

  const handleRoleToggle = useCallback(async (role: string, add: boolean) => {
    setLoading(true)
    try {
      if (add) {
        await addRoleToUser(await getAccessToken(), role, profile.sub)
      } else {
        await removeRoleFromUser(await getAccessToken(), role, profile.sub)
      }
      await loadData()
    } finally {
      setLoading(false)
    }
  }, [loadData, setLoading, profile.sub, getAccessToken])

  const amountItems = () => {
    if (profileDetails?.amounts) {
      return (
        <List component="div" disablePadding>
          {Object.keys(profileDetails.amounts)
            .map(coin => ({
              coin,
              value: profileDetails.amounts![coin].eurValue,
              amount: profileDetails.amounts![coin].amount,
            }))
            .sort((a, b) => b.value - a.value)
            .map(({coin, value, amount}) => (
              <ListItem key={coin} sx={{pl: 4}}>
                <ListItemIcon><CoinImage coin={coin}/></ListItemIcon>
                <ListItemText primary={`${formatCoin(amount)} ${coin}`} secondary={formatEur(value)}/>
              </ListItem>
            ))}
        </List>
      )
    }
    return <></>
  }

  return (
    <Card sx={{m: 1}}>
      <CardHeader
        onClick={() => {
          setExpanded(!expanded)
          if (!profileDetails) {
            loadData()
          }
        }}
        avatar={
          <Avatar aria-label="avatar" src={profile.picture}/>
        }
        title={profile.displayName}
        subheader={profile.email}
        action={<IconButton>{expanded ? <ExpandLess/> : <ExpandMore/>}</IconButton>}
      />
      <Collapse in={expanded} unmountOnExit>
        <CardContent>
          <List>
            {
              loading && (
                <>
                  <Skeleton sx={{mb: -2}} height={80} width="100%"/>
                  <Skeleton sx={{mb: -2}} height={80} width="100%"/>
                  <Skeleton sx={{mb: -2}} height={80} width="100%"/>
                  <Skeleton sx={{mb: -2}} height={80} width="100%"/>
                  <Skeleton sx={{mb: -2}} height={80} width="100%"/>
                </>
              )}
            {!loading && (
              <>
                <ListItem>
                  <ListItemText primary={profileDetails?.sub} secondary="Sub"/>
                  <IconButton edge="end" onClick={() => {
                    copy(profileDetails?.sub || '')
                    addToastMessage('successfully copied', MessageSeverity.success)
                  }}>
                    <CopyAll/>
                  </IconButton>
                </ListItem>
                <ListItem>
                  <ListItemText primary={profileDetails?.displayName} secondary={profileDetails?.name}/>
                </ListItem>
                <ListItem>
                  <ListItemText primary={profileDetails?.email} secondary="Email"/>
                  <IconButton edge="end" onClick={() => {
                    copy(profileDetails?.email || '')
                    addToastMessage('successfully copied', MessageSeverity.success)
                  }}>
                    <CopyAll/>
                  </IconButton>
                </ListItem>
                {!!wallets?.wallets?.length && (
                  <>
                    <ListItemButton onClick={() => setWalletsOpen(!walletsOpen)}>
                      <ListItemText primary="Wallets" secondary={wallets?.wallets?.length}/>
                      {walletsOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                    <Collapse in={walletsOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding sx={{pl: 2}}>
                        {wallets?.wallets?.map(w => (
                          <ListItem key={w.id}>
                            <ListItemIcon>
                              {
                                w.type === WalletType.static ?
                                  <CoinImage coin={(w.data as StaticWalletData).coin}/> :
                                  <CoinImage coin={(w.data as ChainWalletData).coin}/>
                              }
                            </ListItemIcon>
                            <ListItemText primary={getWalletText(w)}
                                          secondary={shorten(getWalletSecondaryText(w))}/>
                            {w.type === WalletType.blockchain && (
                              <ListItemSecondaryAction>
                                <IconButton onClick={() => {
                                  copy((w.data as ChainWalletData).address)
                                  addToastMessage('successfully copied', MessageSeverity.success)
                                }}>
                                  <CopyAll/>
                                </IconButton>
                              </ListItemSecondaryAction>
                            )}
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </>
                )}
                {!!exchanges?.exchanges?.length && (
                  <>
                    <ListItemButton onClick={() => setExchangesOpen(!exchangesOpen)}>
                      <ListItemText primary="Exchanges" secondary={exchanges?.exchanges?.length}/>
                      {exchangesOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                    <Collapse in={exchangesOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding sx={{pl: 2}}>
                        {exchanges?.exchanges?.map(e => (
                          <ListItem key={e.id}>
                            <ListItemText primary={e.type} secondary={e.id}/>
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </>
                )}
                {profileDetails?.aths?.overall?.eurValue && (
                  <ListItem>
                    <ListItemText
                      primary={formatEur(profileDetails?.aths?.overall?.eurValue)}
                      secondary={`ATH ${profileDetails?.aths?.overall?.time || ''}`}
                    />
                  </ListItem>
                )}
                <ListItemButton onClick={() => setAmountsOpen(!amountsOpen)}>
                  <ListItemText
                    primary={
                      profileDetails?.amounts &&
                      formatEur(
                        Object.keys(profileDetails.amounts)
                          .map(c => profileDetails.amounts?.[c]?.eurValue || 0)
                          .reduce((v1, v2) => v1 + v2, 0)
                      )
                    }
                    secondary="Overall"
                  />
                  {amountsOpen ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={amountsOpen} timeout="auto" unmountOnExit>
                  {amountItems()}
                </Collapse>
                <ListItemButton onClick={() => setRolesOpen(!rolesOpen)}>
                  <ListItemText primary={profileDetails?.roles?.join(', ')} secondary="Roles"/>
                  {rolesOpen ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={rolesOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {Object.keys(Role).map(r => (
                      <ListItemButton key={r} sx={{pl: 4}}>
                        <ListItemText primary={r}/>
                        <Switch
                          edge="end"
                          onChange={() => handleRoleToggle(r, !profileDetails?.roles?.includes(r))}
                          checked={profileDetails?.roles?.includes(r)}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </>
            )}
          </List>
        </CardContent>
      </Collapse>
    </Card>
  )
}

const getWalletText = (wallet: WalletItem) => {
  switch (wallet.type) {
    case WalletType.blockchain:
      return (wallet.data as ChainWalletData).coin
    case WalletType.static:
      return (wallet.data as StaticWalletData).coin
    default:
      throw new Error(`unknown wallet type ${wallet.type}`)
  }
}

const getWalletSecondaryText = (wallet: WalletItem) => {
  switch (wallet.type) {
    case WalletType.blockchain:
      return `#${(wallet.data as ChainWalletData).address}`
    case WalletType.static:
      return `${(wallet.data as StaticWalletData).amount} ${(wallet.data as StaticWalletData).coin}`
    default:
      throw new Error(`unknown wallet type ${wallet.type}`)
  }
}