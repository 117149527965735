import {buildAccessTokenHeader} from './clientUtils'
import {Coin} from './WalletClient'
import {fetchInternally} from './withTimeoutHandling'

const apiDomain = process.env.REACT_APP_API_DOMAIN

export const loadCoinInfos = async (accessToken: string, coins: Coin[]): Promise<CoinInfoItem[]> =>
  fetchInternally<CoinInfoItem[]>(() => fetch(`${apiDomain}/rates`, {
      headers: buildAccessTokenHeader(accessToken),
      method: 'POST',
      body: JSON.stringify({coins: [...coins, Coin.usdc]}),
    })
  )

export type CoinInfoItemDelta = {
  hour: number
  day: number
  week: number
  month: number
  quarter: number
  year: number
}

export type CoinInfoItem = {
  code: string
  rate: number
  allTimeHighUSD: number
  delta: CoinInfoItemDelta
}
