import {TradeHistoryItem} from '../../../clients/ExchangeClient'
import React, {useState} from 'react'
import {Card, CardContent, CardHeader, Collapse, IconButton, Typography} from '@mui/material'
import {CoinImage} from '../../utils/CoinImage'
import {formatCoin, formatEur} from '../../utils/round'
import {ExpandLess, ExpandMore} from '@mui/icons-material'

export interface TradeHistoryCardProps {
  trade: TradeHistoryItem
}

export const TradeHistoryCard = ({trade}: TradeHistoryCardProps) => {
  const [open, setOpen] = useState<boolean>()
  return (
    <Card sx={{m: 1}}>
      <CardHeader avatar={<CoinImage coin={trade.coin}/>}
                  onClick={() => setOpen(!open)}
                  title={
                    <Typography
                      color={trade.type === 'buy' ? 'green' : 'red'}
                      fontWeight="bold"
                    >
                      {trade.type} {trade.coin} - {formatEur(Number(trade.cost))}
                    </Typography>
                  }
                  subheader={
                    new Date(trade.time * 1000)
                      .toISOString()
                      .substring(0, 16)
                      .replace('T', ' ')
                  }
                  action={
                    <IconButton>
                      {open ? <ExpandLess fontSize="large"/> : <ExpandMore fontSize="large"/>}
                    </IconButton>
                  }
      />
      <Collapse in={open} unmountOnExit>
        <CardContent>
          <Typography>Cost: {formatEur(Number(trade.cost))}</Typography>
          <Typography>Price: {formatCoin(Number(trade.price))} €</Typography>
          <Typography>Amount: {formatCoin(Number(trade.vol))} {trade.coin}</Typography>
          <Typography>Coin: {trade.coin}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  )
}