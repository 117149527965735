import {buildAccessTokenHeader} from './clientUtils'
import {CoinAmount} from '../components/types'
import {Coin} from './WalletClient'

const apiDomain = process.env.REACT_APP_API_DOMAIN

export const createExchange = async (accessToken: string, request: ExchangeRequest) => {
  await fetch(`${apiDomain}/exchanges`, {
    headers: buildAccessTokenHeader(accessToken),
    method: 'POST',
    body: JSON.stringify(request),
  })
}

export const listOwnExchanges = async (accessToken: string) => {
  const response = await fetch(`${apiDomain}/exchanges`, {
    headers: buildAccessTokenHeader(accessToken),
  })
  return await response.json() as ExchangeResponse
}

export const listTradeHistory = async (accessToken: string) => {
  const response = await fetch(`${apiDomain}/exchanges/orders/history`, {
    headers: buildAccessTokenHeader(accessToken),
  })
  return await response.json() as { trades: TradeHistoryItem[] }
}

export const listExchangesForProfile = async (accessToken: string, sub: string) => {
  const response = await fetch(`${apiDomain}/admin/exchanges/profile/${sub}`, {
    headers: buildAccessTokenHeader(accessToken),
  })
  return await response.json() as ExchangeResponse
}

export const testExchangeCredentials = async (
  accessToken: string,
  type: ExchangeType,
  key: string,
  secret: string,
) => {
  const response = await fetch(`${apiDomain}/exchanges/test`, {
    method: 'POST',
    headers: buildAccessTokenHeader(accessToken),
    body: JSON.stringify({
      type,
      key,
      secret,
    }),
  })
  return await response.json() as ExchangeTestResult
}

export const deleteExchange = async (accessToken: string, id: string) => {
  await fetch(`${apiDomain}/exchanges/${id}`, {
    method: 'DELETE',
    headers: buildAccessTokenHeader(accessToken),
  })
}

export type ExchangeRequest = {
  type: ExchangeType
  apiKey: string
  apiSecret: string
}

export enum ExchangeType {
  kraken = 'kraken',
}

export type ExchangeResponse = {
  exchanges: ExchangeItem[]
}

export type ExchangeItem = {
  id: string
  sub: string
  created: string
  updated: string
  type: ExchangeType
  apiKey: string
  apiSecret: string
}

export type ExchangeTestResult = {
  items?: CoinAmount[]
  error?: string
}

export type TradeHistoryItem = {
  txId: string,
  pair: string,
  time: number,
  type: string,
  price: string,
  cost: string,
  vol: string,
  coin: Coin,
}