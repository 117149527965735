import React, {useCallback, useState} from 'react'
import {
  Card,
  CardHeader,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import {CopyAll, DeleteForever, ExpandLess, ExpandMore, Grid3x3, Key} from '@mui/icons-material'
import {EditableListItem} from '../utils/EditableListItem'
import {deleteExchange, ExchangeItem} from '../../clients/ExchangeClient'
import {ExchangeImage} from '../utils/ExchangeImage'
import {addToastMessage} from '../../stores/MessageStore'
import {MessageSeverity} from '../utils/MessageSnackbar'
import {useStringShortener} from '../utils/useStringShortener'
import {useCopyToClipboard} from 'usehooks-ts'
import {ConfirmationButton} from '../utils/ConfirmationButton'
import {useGetAccessToken} from '../utils/getAccessToken'

export type ExchangeListItemProps = {
  exchange: ExchangeItem
  onReload: () => void
}

export const ExchangeCard = ({exchange: {id, apiKey, apiSecret, type}, onReload}: ExchangeListItemProps) => {
  const getAccessToken = useGetAccessToken()
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [newKey, setNewKey] = useState<string>(apiKey)
  const [newSecret, setNewSecret] = useState<string>(apiSecret)
  const {shorten} = useStringShortener()
  const [, copy] = useCopyToClipboard()

  const handleDelete = useCallback(async () => {
    setLoading(true)
    try {
      await deleteExchange(await getAccessToken(), id)
      onReload()
      addToastMessage('Successfully deleted', MessageSeverity.success)
    } finally {
      setLoading(false)
    }
  }, [setLoading, getAccessToken, id, onReload])

  const detailContent = loading ? (
    <>
      <Skeleton variant="rounded" width="100%" height={80}/>
      <Skeleton variant="rounded" width="100%" height={80}/>
      <Skeleton variant="rounded" width="100%" height={80}/>
    </>
  ) : (
    <>
      <List>
        <ListItem>
          <ListItemIcon><Grid3x3/></ListItemIcon>
          <ListItemText primary={shorten(`#${id}`)} secondary="Id"/>
          <ListItemSecondaryAction>
            <IconButton edge="end" onClick={() => {
              copy(id || '')
              addToastMessage('successfully copied', MessageSeverity.success)
            }}>
              <CopyAll/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <EditableListItem
          name="API key"
          icon={<Key/>}
          defaultValue={newKey}
          copyable
          onChange={newValue => setNewKey(newValue)}/>
        <EditableListItem
          name="API secret"
          icon={<Key/>}
          secretValue
          copyable
          defaultValue={newSecret}
          onChange={newValue => setNewSecret(newValue)}/>
      </List>
    </>
  )

  return (
    <Card sx={{width: '100%', m: 1}}>
      <CardHeader
        onClick={(e: MouseEvent) => {
          if (!e.defaultPrevented) {
            setOpen(!open)
          }
        }}
        avatar={<ExchangeImage exchange={type}/>}
        title={<Typography textTransform="uppercase">{type}</Typography>}
        action={
          loading ? (
              <Stack direction="row">
                <Skeleton variant="circular" animation="wave" width={36} height={36}/>
                <Skeleton variant="circular" animation="wave" width={36} height={36} sx={{ml: 2}}/>
              </Stack>
            ) :
            <>
              <ConfirmationButton
                button={{color: 'error'}}
                actionName={`delete exchange ${id}`}
                action={handleDelete}
              >
                <DeleteForever/>
              </ConfirmationButton>
              <IconButton>{open ? <ExpandLess/> : <ExpandMore/>}</IconButton>
            </>
        }
      />
      <Collapse in={open} unmountOnExit>
        {detailContent}
      </Collapse>
    </Card>
  )
}