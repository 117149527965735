import {Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material'
import React, {PropsWithChildren, useState} from 'react'

export type ConfirmationButtonProps = {
  button: Exclude<ButtonProps, 'onClick'>
  action: () => void
  actionName: string
} & PropsWithChildren

export const ConfirmationButton = ({button, action, actionName, children}: ConfirmationButtonProps) => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <>
      <Button {...button} onClick={e => {
        e.preventDefault()
        setOpen(true)
      }}>
        {children}
      </Button>
      <Dialog sx={{m: 2}} open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you really want to {actionName}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={() => {
            action()
            setOpen(false)
          }}>Continue</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}