import React, {PropsWithChildren, useContext} from 'react'
import ScrollToTop from './utils/ScrollToTop'
import {AppBar, Avatar, Box, Button, IconButton, Toolbar, Typography} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {useAuth0} from '@auth0/auth0-react'
import {Role} from './types'
import {AdminPanelSettings, Settings} from '@mui/icons-material'
import {RoleContext} from './security/ProtectedRoute'

export const PageLayout = ({children}: PropsWithChildren) => {
  const roles = useContext(RoleContext)
  const {user} = useAuth0()
  const navigate = useNavigate()

  return (
    <>
      <ScrollToTop/>
      <AppBar position="relative" color="primary">
        <Toolbar>
          <Button
            variant="text"
            startIcon={<img src="/hand.svg" height={38} style={{marginBottom: 3}} alt="logo"/>}
            style={{marginLeft: -8}}
            onClick={() => navigate('/dashboard')}
          >
            <Typography
              color="black"
              variant="h4"
              fontWeight="bold"
              sx={{textTransform: 'none'}}
            >
              h0pe
            </Typography>
          </Button>
          {user && (<>
              <Box sx={{flexGrow: 1}}></Box>
              {roles?.includes(Role.admin) ? (
                <Box sx={{flexGrow: 0}}>
                  <IconButton onClick={() => navigate('/admin/users')}>
                    <AdminPanelSettings fontSize="large"/>
                  </IconButton>
                </Box>
              ) : undefined}
              <Box sx={{flexGrow: 0}}>
                <IconButton sx={{mr: 1}} onClick={() => navigate('/settings')}>
                  <Settings fontSize="large"/>
                </IconButton>
                <IconButton sx={{p: 0}} onClick={() => navigate('/profile')}>
                  <Avatar alt={user.name} src={user.picture}/>
                </IconButton>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <div style={{marginTop: 12}}>
        {children}
      </div>
    </>
  )
}