import React, {useCallback, useState} from 'react'
import {
  Card,
  CardHeader,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import {CopyAll, DeleteForever, ExpandLess, ExpandMore, Grid3x3, Percent, TextFields} from '@mui/icons-material'
import {
  deleteSavingsPlan,
  PercentageSavingsPlanData,
  SavingsPlanItem,
  SavingsPlanType,
  SMASavingsPlanData,
} from '../../../clients/SavingsPlanClient'
import {CoinImage} from '../../utils/CoinImage'
import {addToastMessage} from '../../../stores/MessageStore'
import {MessageSeverity} from '../../utils/MessageSnackbar'
import {useStringShortener} from '../../utils/useStringShortener'
import {useCopyToClipboard} from 'usehooks-ts'
import {ConfirmationButton} from '../../utils/ConfirmationButton'
import {useGetAccessToken} from '../../utils/getAccessToken'

export type SavingsPlanCardProps = {
  savingsPlan: SavingsPlanItem
  onReload: () => void
}

export const SavingsPlanCard = (
  {
    savingsPlan,
    onReload,
  }: SavingsPlanCardProps) => {
  const getAccessToken = useGetAccessToken()
  const [loading, setLoading] = useState<boolean>(false)

  const handleDelete = useCallback(async (id: string) => {
    setLoading(true)
    try {
      await deleteSavingsPlan(await getAccessToken(), id)
      onReload()
      addToastMessage('Successfully deleted', MessageSeverity.success)
    } finally {
      setLoading(false)
    }
  }, [setLoading, getAccessToken, onReload])

  if (loading) {
    return <Skeleton height={250} width="100%"/>
  }

  switch (savingsPlan.type) {
    case SavingsPlanType.percentage:
      return (
        <PercentageSavingsPlanCard
          savingsPlan={savingsPlan}
          loading={loading}
          data={savingsPlan.data as PercentageSavingsPlanData}
          onDelete={handleDelete}/>
      )
    case SavingsPlanType.sma:
      return (
        <SMASavingsPlanCard
          savingsPlan={savingsPlan}
          data={savingsPlan.data as SMASavingsPlanData}
          loading={loading}
          onDelete={handleDelete}/>
      )
    default:
      throw new Error(`unknown savingsplan type ${savingsPlan.type}`
      )
  }
}

export type PercentageSavingsPlanCardProps = {
  savingsPlan: SavingsPlanItem
  data: PercentageSavingsPlanData
  loading: boolean
  onDelete: (id: string) => void
}

export const PercentageSavingsPlanCard = (
  {
    savingsPlan: {id, coin, description, perBuyPercentage, perBuyMin, perBuyMax},
    data: {percentage},
    loading,
    onDelete,
  }: PercentageSavingsPlanCardProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const {shorten} = useStringShortener()
  const [, copy] = useCopyToClipboard()

  return (
    <Card sx={{m: 0.5}}>
      <CardHeader
        onClick={(e: MouseEvent) => {
          if (!e.defaultPrevented) {
            setOpen(!open)
          }
        }}
        avatar={<CoinImage coin={coin}/>}
        title={<Typography textTransform="uppercase">{coin}</Typography>}
        subheader={description}
        action={
          loading ? (
              <Stack direction="row">
                <Skeleton variant="circular" animation="wave" width={36} height={36}/>
                <Skeleton variant="circular" animation="wave" width={36} height={36} sx={{ml: 2}}/>
              </Stack>
            ) :
            <>
              <ConfirmationButton
                button={{color: 'error'}}
                action={() => onDelete(id)}
                actionName={`delete savings plan ${id}`}
              >
                <DeleteForever/>
              </ConfirmationButton>
              <IconButton>{open ? <ExpandLess/> : <ExpandMore/>}</IconButton>
            </>
        }
      />
      <Collapse in={open} unmountOnExit>
        <List>
          <ListItem>
            <ListItemIcon><Grid3x3/></ListItemIcon>
            <ListItemText primary={shorten(`#${id}`)} secondary="Id"/>
            <ListItemSecondaryAction>
              <IconButton onClick={() => {
                copy(id)
                addToastMessage('Successfully copied', MessageSeverity.success)
              }}>
                <CopyAll/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon><Percent/></ListItemIcon>
            <ListItemText primary={description} secondary="Description"/>
          </ListItem>
          <ListItem>
            <ListItemIcon><TextFields/></ListItemIcon>
            <ListItemText
              primary={`Buy ${perBuyPercentage}% of the available cash if price falls ${percentage}% during the day`}
              secondary={`Minimum: ${perBuyMin}€ - Maximum: ${perBuyMax}€`}/>
          </ListItem>
        </List>
      </Collapse>
    </Card>
  )
}

export type SMASavingsPlanCardProps = {
  savingsPlan: SavingsPlanItem
  data: SMASavingsPlanData
  loading: boolean
  onDelete: (id: string) => void
}

export const SMASavingsPlanCard = (
  {
    savingsPlan: {id, coin, description, perBuyPercentage, perBuyMin, perBuyMax},
    data: {smaDays},
    loading,
    onDelete,
  }: SMASavingsPlanCardProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const {shorten} = useStringShortener()
  const [, copy] = useCopyToClipboard()

  return (
    <Card sx={{m: 0.5}}>
      <CardHeader
        onClick={(e: MouseEvent) => {
          if (!e.defaultPrevented) {
            setOpen(!open)
          }
        }}
        avatar={<CoinImage coin={coin}/>}
        title={<Typography textTransform="uppercase">{coin}</Typography>}
        subheader={description}
        action={
          loading ? (
              <Stack direction="row">
                <Skeleton variant="circular" animation="wave" width={36} height={36}/>
                <Skeleton variant="circular" animation="wave" width={36} height={36} sx={{ml: 2}}/>
              </Stack>
            ) :
            <>
              <IconButton color="error" onClick={() => onDelete(id)}><DeleteForever/></IconButton>
              <IconButton>{open ? <ExpandLess/> : <ExpandMore/>}</IconButton>
            </>
        }
      />
      <Collapse in={open} unmountOnExit>
        <List>
          <ListItem>
            <ListItemIcon><Grid3x3/></ListItemIcon>
            <ListItemText primary={shorten(`#${id}`)} secondary="Id"/>
            <ListItemSecondaryAction>
              <IconButton onClick={() => {
                copy(id)
                addToastMessage('Successfully copied', MessageSeverity.success)
              }}>
                <CopyAll/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon><Percent/></ListItemIcon>
            <ListItemText primary={description} secondary="Description"/>
          </ListItem>
          <ListItem>
            <ListItemIcon><TextFields/></ListItemIcon>
            <ListItemText
              primary={
                `Buy ${perBuyPercentage}% of the available cash if price falls below ` +
                `${smaDays} simple moving average during the day`
              }
              secondary={`Minimum: ${perBuyMin}€ - Maximum: ${perBuyMax}€`}/>
          </ListItem>
        </List>
      </Collapse>
    </Card>
  )
}