import React, {useCallback, useEffect, useState} from 'react'
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
} from '@mui/material'
import {ExchangeCard} from './ExchangeCard'
import {
  createExchange,
  ExchangeItem,
  ExchangeTestResult,
  ExchangeType,
  listOwnExchanges,
  testExchangeCredentials,
} from '../../clients/ExchangeClient'
import {
  Add,
  BugReport,
  Check,
  Close,
  CurrencyExchange,
  Error,
  ExpandLess,
  ExpandMore,
  Key,
  Save,
} from '@mui/icons-material'
import {EditableListItem, EditableListOptionItem} from '../utils/EditableListItem'
import {ActionButton} from '../utils/ActionButton'
import {addToastMessage} from '../../stores/MessageStore'
import {MessageSeverity} from '../utils/MessageSnackbar'
import {useGetAccessToken} from '../utils/getAccessToken'

export const ExchangeSettings = () => {
  const getAccessToken = useGetAccessToken()
  const [exchanges, setExchanges] = useState<ExchangeItem[]>([])
  const [loading, setLoading] = useState<boolean>()
  const [open, setOpen] = useState<boolean>(false)
  const [type, setType] = useState<ExchangeType>(ExchangeType.kraken)
  const [apiKey, setApiKey] = useState<string>('')
  const [apiSecret, setApiSecret] = useState<string>('')
  const [testResult, setTestResult] = useState<ExchangeTestResult>()

  const loadData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await listOwnExchanges(await getAccessToken())
      setExchanges(response.exchanges)
    } finally {
      setLoading(false)
    }
  }, [setLoading, setExchanges, getAccessToken])

  const handleCreate = useCallback(async () => {
    setLoading(true)
    try {
      if (apiSecret && apiKey && type) {
        await createExchange(await getAccessToken(), {
          apiSecret,
          apiKey,
          type,
        })
        await loadData()
        setApiKey('')
        setApiSecret('')
        setOpen(false)
        setTestResult(undefined)
        addToastMessage('Created successfully', MessageSeverity.success)
      }
    } finally {
      setLoading(false)
    }
  }, [setLoading, apiKey, apiSecret, type, setApiKey, setApiSecret, setOpen, getAccessToken, loadData])

  const handleTest = useCallback(async () => {
    const testResult = await testExchangeCredentials(await getAccessToken(), type, apiKey, apiSecret)
    setTestResult(testResult)
    addToastMessage('Test finished')
  }, [type, apiKey, apiSecret, getAccessToken, setTestResult])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <Grid container>
      <TestResultDialog onClose={() => setTestResult(undefined)} onSave={handleCreate} testResult={testResult}/>
      {loading && (
        <>
          <Skeleton sx={{mb: -3}} height={100} width="100%"/>
          <Skeleton sx={{mb: -3}} height={100} width="100%"/>
        </>
      )}
      {!loading && (
        <>
          {exchanges.map(e => <ExchangeCard key={e.id} onReload={loadData} exchange={e}/>)}
          <List sx={{width: '100%'}}>
            <ListItemButton onClick={() => setOpen(!open)}>
              <ListItemIcon>
                <Add/>
              </ListItemIcon>
              <ListItemText primary="Add exchange"/>
              <ListItemIcon>
                <IconButton edge="end">
                  {open ? <ExpandLess/> : <ExpandMore/>}
                </IconButton>
              </ListItemIcon>
            </ListItemButton>
            <Collapse in={open} unmountOnExit>
              <List component="div" disablePadding>
                <EditableListOptionItem
                  defaultValue={type}
                  icon={<CurrencyExchange/>}
                  name="Type"
                  options={[
                    'kraken',
                  ]}
                  onChange={t => setType(t as ExchangeType)}/>
                <EditableListItem
                  defaultValue={apiKey}
                  icon={<Key/>}
                  name="API key"
                  onChange={newValue => setApiKey(newValue)}/>
                <EditableListItem
                  defaultValue={apiSecret}
                  icon={<Key/>}
                  name="API secret"
                  onChange={newValue => setApiSecret(newValue)}/>
              </List>
              <ActionButton
                sx={{ml: 1, mt: 2}}
                variant="contained"
                color="warning"
                startIcon={<BugReport/>}
                disabled={!type || !apiKey || !apiSecret}
                buttonAction={handleTest}
              >Test</ActionButton>
            </Collapse>
          </List>
        </>
      )}
    </Grid>
  )
}

export type TestResultDialogProps = {
  testResult?: ExchangeTestResult
  onClose: () => void
  onSave: () => void
}

export const TestResultDialog = ({testResult, onClose, onSave}: TestResultDialogProps) => {
  return (
    <Dialog open={!!testResult} onClose={onClose} PaperProps={{sx: {p: 2, width: '95%'}}}>
      <DialogTitle>Test result</DialogTitle>
      <DialogContent>
        {testResult?.error ? (
          <div>
            <h2><Error/> Failed:</h2>
            <Paper sx={t => ({p: 2, background: t.palette.warning.light})}>
              <p>{JSON.stringify(testResult.error)}</p>
            </Paper>
          </div>
        ) : (
          <div>
            <h2><Check/> Success:</h2>
            <Paper sx={t => ({p: 2, background: t.palette.success.light})}>
              <p>{JSON.stringify(testResult?.items)}</p>
            </Paper>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <IconButton onClick={onClose}>
          <Close/>
        </IconButton>
        <Button
          variant="contained"
          disabled={!!testResult?.error}
          color="success"
          startIcon={<Save/>}
          onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}