import {TextField, TextFieldProps} from '@mui/material'
import React, {useState} from 'react'

export type NumberInputProps = Omit<TextFieldProps, 'defaultValue' | 'value' | 'onChange'> & {
  value: number | undefined
  onChange: (newNumber: number | undefined) => void
}

export const NumberInput = (props: NumberInputProps) => {
  const [value, setValue] = useState<string>(props.value?.toString() || '')
  return (
    <TextField
      {...props}
      value={value}
      onChange={e => {
        setValue(e.target.value)
        const newNumber = Number(e.target.value)
        if (!Number.isNaN(newNumber)) {
          props.onChange(newNumber)
        } else {
          props.onChange(undefined)
        }
      }}
      InputProps={{
        ...props.InputProps,
        type: 'number',
      }}
      error={Number.isNaN(Number(value))}
    />
  )
}