import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useCopyToClipboard} from 'usehooks-ts'
import {SharedProfile} from '../types'
import {Euro, ExpandLess, ExpandMore, Share} from '@mui/icons-material'
import {createShareId, fetchSharedProfiles} from '../../clients/ProfileClient'
import {useGetAccessToken} from '../utils/getAccessToken'
import {range} from '../utils/range'
import {CoinImage} from '../utils/CoinImage'
import {formatCoin, formatEur} from '../utils/round'
import {addToastMessage} from '../../stores/MessageStore'
import {MessageSeverity} from '../utils/MessageSnackbar'

export const FriendNumbers = () => {
  const getAccessToken = useGetAccessToken()
  const [loading, setLoading] = useState<boolean>(true)
  const [sharedProfiles, setSharedProfiles] = useState<SharedProfile[]>([])
  const [, copy] = useCopyToClipboard()

  const loadData = useCallback(async () => {
    setLoading(true)
    try {
      const accessToken = await getAccessToken()
      const sharedProfiles = await fetchSharedProfiles(accessToken)
      setSharedProfiles(sharedProfiles)
    } finally {
      setLoading(false)
    }
  }, [setLoading, getAccessToken, setSharedProfiles])

  const share = useCallback(async () => {
    const accessToken = await getAccessToken()
    const id = await createShareId(accessToken)
    const url = `https://h0pe.s0na.de/share/${id}`
    const shareData = {
      title: 'h0pe',
      text: `See my profile on h0pe\n`,
      url,
    }
    if (navigator.share && navigator.canShare(shareData)) {
      await navigator.share(shareData)
    } else {
      await copy(url)
      addToastMessage('Successfully copied share link', MessageSeverity.success)
    }
  }, [getAccessToken])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <>
      <Box sx={{height: 15, width: 20}}/>
      {!loading && !sharedProfiles.length && <Typography variant="h2">No shared profiles yet</Typography>}
      {loading && range(3).map(() => <Skeleton height={80}/>)}
      {sharedProfiles?.map(p => (
        <FriendNumberCard profile={p}/>
      ))}
      <Fab variant="extended"
           color="primary"
           aria-label="refresh"
           onClick={share}
           sx={theme => ({
             position: 'fixed',
             right: 18,
             bottom: 74,
             background: theme.palette.primary.main,
           })}>
        <Share/>
      </Fab>
      <Box sx={{height: 15, width: 20}}/>
    </>
  )
}

interface FriendNumberCardProps {
  profile: SharedProfile
}

export const FriendNumberCard = ({profile}: FriendNumberCardProps) => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <Card sx={{m: 1}}>
      <CardHeader
        onClick={() => {
          setOpen(!open)
        }}
        avatar={
          <Avatar aria-label="avatar" src={profile.picture}/>
        }
        title={profile.displayName}
        action={<IconButton>{open ? <ExpandLess/> : <ExpandMore/>}</IconButton>}
      />
      <Collapse in={open} unmountOnExit>
        <CardContent>
          <List component="div" disablePadding>
            {
              profile.aths?.overall?.eurValue && (
                <ListItem>
                  <ListItemIcon><Euro fontSize="large"/></ListItemIcon>
                  <ListItemText
                    primary={`${formatEur(profile.aths?.overall?.eurValue)}`}
                    secondary={`ATH - ${profile.aths?.overall?.time}`}
                  />
                </ListItem>
              )
            }
            {
              profile.amounts && (
                <ListItem>
                  <ListItemIcon><Euro fontSize="large"/></ListItemIcon>
                  <ListItemText
                    primary={
                      formatEur(
                        Object.keys(profile.amounts)
                          .map(a => profile.amounts?.[a]?.eurValue || 0)
                          .reduce((v1, v2) => v1 + v2, 0)
                      )
                    }
                    secondary="Wealth"
                  />
                </ListItem>
              )
            }
            {profile.amounts && Object.keys(profile.amounts)
              .map(coin => ({
                coin,
                value: profile.amounts![coin].eurValue,
                amount: profile.amounts![coin].amount,
              }))
              .sort((a, b) => b.value - a.value)
              .map(({coin, value, amount}) => (
                <ListItem key={coin}>
                  <ListItemIcon><CoinImage coin={coin}/></ListItemIcon>
                  <ListItemText primary={`${formatCoin(amount)} ${coin}`} secondary={formatEur(value)}/>
                </ListItem>
              ))}
          </List>
        </CardContent>
      </Collapse>
    </Card>
  )
}