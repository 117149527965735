import {Button, Dialog, Paper, Typography} from '@mui/material'
import React, {useState} from 'react'

export const ImpressButton = () => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <>
      <Button
        color="primary"
        style={{right: 8, fontWeight: 'bold'}}
        onClick={() => setOpen(true)}
      >
        impress
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Paper elevation={1} style={{padding: 25}}>
          <Typography fontWeight="bold" variant="h3" >Impress</Typography>
          <Typography fontWeight="bold" variant="h5" sx={{mt: 1}}>Info for § 5 TMG</Typography>
          <Typography sx={{mt: 1}}>
            Stefan Hoferer<br/>
            Schönberg 4<br/>
            84564 Oberbergkirchen
          </Typography>
          <Typography fontWeight="bold" variant="h5" sx={{mt: 1}}>Contact</Typography>
          <Typography sx={{mt: 1}}>
            Phone: (+49) 0162 339 0867<br/>
            E-Mail: stefan@fancynetwork.net
          </Typography>
          <Button
            style={{float: 'right', marginTop: 15}}
            color="primary"
            variant="contained"
            onClick={() => setOpen(false)}>
            Close
          </Button>
        </Paper>
      </Dialog>
    </>
  )
}
