import {Avatar} from '@mui/material'
import {ExchangeType} from '../../clients/ExchangeClient'

export type ExchangeImageProps = {
  exchange: ExchangeType
}

export const ExchangeImage = ({exchange}: ExchangeImageProps) => (
  <Avatar src={`/${exchange}.png`} variant="square" imgProps={{
    style: {
      objectFit: 'contain',
    },
  }}/>
)