import React, {createContext, PropsWithChildren, useCallback, useEffect, useState} from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import {useAuth0} from '@auth0/auth0-react'
import {Skeleton} from '@mui/material'

export const RoleContext = createContext<string[] | undefined>(undefined)

export interface ProtectedRouteProps extends PropsWithChildren {
  necessaryRole: string
  redirectPath?: string
}

export const ProtectedRoute = ({necessaryRole, redirectPath = '/', children}: ProtectedRouteProps) => {
  const {getIdTokenClaims} = useAuth0()
  const [loading, setLoading] = useState(true)
  const [roles, setRoles] = useState<string[] | undefined>()

  const loadRoles = useCallback(async () => {
    setLoading(true)
    try {
      const roles: string[] | undefined = (await getIdTokenClaims())?.['h0pe/roles']
      if (roles) {
        setRoles(roles)
      }
    } finally {
      setLoading(false)
    }
  }, [setLoading, getIdTokenClaims, setRoles])

  useEffect(() => {
    loadRoles()
  }, [loadRoles])

  if (loading) {
    return (
      <>
        <Skeleton height={80} width="30%"/>
        <Skeleton height={80} width="100%"/>
        <Skeleton height={80} width="100%"/>
      </>
    )
  }

  if (!roles?.includes(necessaryRole)) {
    // eslint-disable-next-line no-console
    console.error('route forbidden -> redirecting')
    return <Navigate to={redirectPath} replace/>
  }
  return children ? <RoleContext.Provider value={roles}>{children}</RoleContext.Provider> : <Outlet/>
}