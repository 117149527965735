import {color, ColorSet, Legend, percent, Root} from '@amcharts/amcharts5'
import {useLayoutEffect} from 'react'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import {PieChart} from '@amcharts/amcharts5/.internal/charts/pie/PieChart'
import {PieSeries} from '@amcharts/amcharts5/.internal/charts/pie/PieSeries'
import {useMedia} from '../utils/useMedia'

export type PieChartProps = {
  items: {
    name: string
    value: number
  }[]
}

export const DonutChart = ({items}: PieChartProps) => {
  const media = useMedia()
  useLayoutEffect(() => {
    const root = Root.new('donutdiv')

    root.setThemes([
      am5themes_Animated.new(root),
    ])


    const chart = root.container.children.push(
      PieChart.new(root, {
        radius: media.isSmall ? 60 : percent(70),
        endAngle: 270,
        layout: root.verticalLayout,
        innerRadius: percent(50),
      })
    )

    const series = chart.series.push(
      PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'name',
        endAngle: 270,
      })
    )

    series.set('colors', ColorSet.new(root, {
      colors: [
        color('#B347E6'),
        color('#c28e4b'),
        color('#74944c'),
        color('#597cc9'),
        color('#544b38'),
        color('#e1508c'),
        color('#71c2b7'),
      ],
    }))

    series.slices.template.setAll({
      strokeWidth: 2,
      stroke: color(0xffffff),
      cornerRadius: 10,
      shadowOpacity: 0.1,
      shadowOffsetX: 2,
      shadowOffsetY: 2,
      shadowColor: color(0x000000),
    })

    series.slices.template.states.create('hover', {
      shadowOpacity: 1,
      shadowBlur: 10,
    })

    series.ticks.template.setAll({
      strokeOpacity: 0.4,
      strokeDasharray: [2, 2],
    })

    series.states.create('hidden', {
      endAngle: -90,
    })

    series.data.setAll(items)

    const legend = chart.children.push(Legend.new(root, {
      centerX: percent(50),
      x: percent(50),
      marginTop: 15,
      marginBottom: 15,
      marginLeft: 15,
      marginRight: 15,
    }))
    legend.markerRectangles.template.adapters.add('fillGradient', function () {
      return undefined
    })
    legend.data.setAll(series.dataItems)

    series.appear(1000, 100)

    return () => root.dispose()
  }, [items, media])

  return (
    <div>
      <div id="donutdiv" style={{width: '100%', height: '500px'}}></div>
    </div>
  )
}